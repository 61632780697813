import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function Receipts({ payrolls }) {
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Recibos
        </Typography>
        <Alert severity="info">
          A informação disponibilizada é relativa aos últimos 6 meses!
        </Alert>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={payrolls}
          getRowId={(row) => row.PAYROLL_ID}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "PAYROLL_DATE",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  { field: "PAYROLL_ID", hide: true },
  { field: "PAYROLL_YEAR", headerName: "Ano", minWidth: 130, flex: 0 },
  {
    field: "PAYROLL_NUMBER",
    headerName: "Nº do Recibo",
    minWidth: 130,
    flex: 1,
  },
  { field: "PAYROLL_DATE", headerName: "Data", minWidth: 130, flex: 1 },
  {
    field: "PAYROLL_BASE_SALARY",
    headerName: "Venc.Base",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "PAYROLL_OTHER_SALARY",
    headerName: "Venc.Outros",
    minWidth: 130,
    flex: 1,
  },
  { field: "PAYROLL_TAXES", headerName: "Impostos", minWidth: 130, flex: 1 },
  {
    field: "PAYROLL_SALARY_ADVENCES",
    headerName: "Abonos",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "PAYROLL_OTHER_DEDUCTIONS",
    headerName: "Desc.Vales",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "PAYROLL_LIQUID_AMOUNT",
    headerName: "Venc.Líquido",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "PAYROLL_HAS_PDF",
    headerName: "PDF",
    headerAlign: "center",
    align: "center",
    minWidth: 50,
    flex: 0,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div>
          {params.row.PAYROLL_HAS_PDF !== "0" && (
           <a href={ `${params.value}`} target="_blank" rel="noopener" download>
              <Button
                variant="contained"
                className="btnPrimary"
                startIcon={<FileDownloadIcon className="ml-15" />}
              ></Button>
            </a>
          )}
          {params.row.PAYROLL_HAS_PDF == "0" && (
           <Typography variant="subtitle2">Ficheiro <br/> indisponível</Typography> 
          )}
        </div>
      );
    },
  },
];
