import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Header from "../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import Message from "../components/Message";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold !important",
    color: "#48aa37",
  },
});

export default function Ativate(props) {
  const classes = useStyles();

  //E-mail from register
  const { state } = useLocation();
  const { EMAIL_UTILIZADOR } = state;
  //

  const { register, handleSubmit, watch } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setAlert(true);
  };

  // console.log("TESTE", props.location.state);
  console.log(watch("EMAIL_UTILIZADOR"));

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "E-mail reenviado com sucesso!";

  return (
    <div>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        pb={4}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            marginTop: { xs: "15vh", sm: "35vh", md: "35vh", lg: "35vh" },
          }}
        >
          <Typography variant="h5" pb={3} className={classes.title}>
            Success Work
          </Typography>
          <Typography variant="h6" p={3}>
            Obrigado por se inscrever na <strong>Success Work!</strong>
          </Typography>
          <Typography variant="subtitle1" pb={3} className={classes.title}>
            Por favor, aceda ao seu e-mail para efetuar a ativação do seu registo e iniciar a utilização do portal de emprego
          </Typography>
          <Divider />
     
          <Typography variant="h6" pt={2} pb={5}>
            Caso não tenha recebido o e-mail de ativação na sua caixa de entrada, verifique no SPAM ou clique no link abaixo para reenviar:
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              {...register("EMAIL_UTILIZADOR")}
              value={EMAIL_UTILIZADOR}
            />
            <Button
              type="submit"
              value="submit"
              variant="contained"
              className="btnPrimary"
            >
              ENVIAR E-MAIL
            </Button>
            <Message
              message={message}
              alert={alert}
              setAlert={(updated) => setAlert(updated)}
            />
          </form>
          <Typography variant="h6" pt={5}>
            Poderá efetuar o login na nossa plataforma para atualizar a sua
            informação de candidatura e concorrer a oportunidades de emprego.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
