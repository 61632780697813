import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function Contracts({ contracts }) {
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Contratos
        </Typography>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={contracts}
          getRowId={(row) => row.CONTRACT_ID}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "CONTRACT_DATE",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  { field: "CONTRACT_ID", hide: true },
  { field: "CONTRACT_YEAR", headerName: "Ano", width: 100, flex: 1 },
  { field: "CONTRACT_NAME", headerName: "Local de trabalho", minWidth: 130, flex: 1 },
  {
    field: "CONTRACT_CATEGORY",
    headerName: "Categoria",
    minWidth: 130,
    flex: 1,
  },
  { field: "CONTRACT_DATE", headerName: "Data de início", minWidth: 130, flex: 1 },
  { field: "CONTRACT_STATE", headerName: "Estado", minWidth: 130, flex: 1 },
  {
    field: "CONTRACT_LINK",
    headerName: "PDF",
    headerAlign: "center",
    align: "center",
    minWidth: 50,
    flex: 0,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div>
          {params.row.CONTRACT_HAS_PDF !== "0" && (
           <a target="_blank" rel="noopener" download>
              <Button
                href={`${params.value}`}
                variant="contained"
                className="btnPrimary"
                startIcon={<FileDownloadIcon className="ml-15" />}
              ></Button>
            </a>
          )}
          {params.row.CONTRACT_HAS_PDF == "0" && (
           <Typography variant="subtitle2">Ficheiro <br/> indisponível</Typography> 
          )}
        </div>
      );
    },
  },
];
