import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AdjustIcon from '@mui/icons-material/Adjust';
import BoltIcon from '@mui/icons-material/Bolt';


export default function ListOffer(props) {
  //Get Offers
  const [offers, setOffers] = useState([]);
  const [apiErrorOffer, setApiErrorOffer] = useState(false);

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(
        `${process.env.REACT_APP_API}/offer?OFFER_JOB_ID=${props.inputValueJob}&RECRUITMENT_GROUP=${props.inputValueGroup}`,
        { signal: abortCont.signal }
      )
      .then((res) => {
        // console.log(res.data);
        setOffers(res.data);
        setApiErrorOffer(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorOffer(true);
        }
      });

    return () => abortCont.abort();
  }, [props]);
  //

  //Props
  const items = props.numberOfItems;

  //Ver Mais Button
  const [numberOfItems, setNumberOfItems] = useState(items);

  const showMore = () => {
    if (numberOfItems <= offers.length) {
      setNumberOfItems(numberOfItems + 6);
    } else {
      setNumberOfItems(offers.length);
    }
  };
  //

  //Reset Button
  const resetBtn = () => {
    setNumberOfItems(6);
    //Move para o topo da página
    window.scrollTo(0, 0);
  };

  //ScrollTop
  const scrollTop = () => {
    //Move para o topo da página
    window.scrollTo(0, 0);
  };
  
  function getLocalOfferFormat(distrito, concelho, freguesia, local){
    if(local !== null)  return local;
    if(freguesia !== null) return freguesia;
    if(concelho !== null) return concelho;
    if(distrito !== null) return distrito;
  
    return "";
  }
  
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
          textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
        }}
      >
        {offers.slice(0, numberOfItems).map((offer) => (
          <Grid
            item
            pb={1}
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={offer.OFFER_ID}
            sx={{ padding: { xs: "20px", sm: "20px", md: "20px", lg: "40px" } }}
          >
            <Link
              to={`/offer/${offer.OFFER_ID}`}
              style={{ textDecoration: "none" }}
            >
              <Card className="card" onClick={scrollTop}>
                <CardContent>
                  <Typography
                    p={3}
                    variant="h5"
                    component="div"
                    className="cardJob">
                    {offer.OFFER_AD_TITLE ? offer.OFFER_AD_TITLE : offer.OFFER_JOB}
                  </Typography>
                  <Typography p={3} variant="body2" className="cardCity">
                    <FmdGoodIcon className="iconoffer" />  {getLocalOfferFormat(offer.OFFER_AD_DISTRITO,offer.OFFER_AD_CONCELHO, offer.OFFER_AD_FREGUESIA, offer.OFFER_AD_LOCAL)}
                    <br />
                    { offer.OFFER_JOB_GROUP &&<>
                      <BoltIcon className="iconoffer" /> {offer.OFFER_JOB_GROUP}
                    </>}
                    <br />
                    {offer.WORKING_HOURS && <>
                    <AdjustIcon className="iconoffer" />{offer.WORKING_HOURS}</>}
                  </Typography>
                  <Typography p={3} variant="body2" className="cardDate">
                    Publicado em {offer.CREATED_DATE}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
        {offers.length === 0 && (
          <Grid container alignItems="center" justifyContent="center" p={10}>
            <Typography variant="h4" sx={{ color: "#3ea938" }}>
              Sem ofertas encontradas
            </Typography>
          </Grid>
        )}
        {!apiErrorOffer && (
          <Grid item p={5} xs={12}>
            <Stack spacing={1} direction="row" justifyContent="center">
              {offers.length > 6 && (
                <Button
                  variant="contained"
                  className="btnPrimary"
                  onClick={showMore}
                >
                  Ver Mais
                </Button>
              )}

              {numberOfItems > 6 && (
                <Button
                  variant="contained"
                  className="btnPrimary"
                  onClick={resetBtn}
                >
                  Repor
                </Button>
              )}
            </Stack>
          </Grid>
        )}
        {apiErrorOffer && (
          <Grid container alignItems="center" justifyContent="center" p={10}>
            <Typography variant="h4">Informação não disponível</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
