import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function SentMessage() {
  const [messageEdit, setMessageEdit] = useState(null);
  const { id } = useParams();

  const { control, reset } = useForm({
    defaultValues: messageEdit,
  });

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`http://localhost:8000/messages/${id}`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setMessageEdit(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, [id]);
  //

  //Default values quando faz refresh da página
  useEffect(() => {
    reset(messageEdit);
  }, [reset, messageEdit]);

  const [numfiles, setNumFiles] = useState(0);
  const [files, setFiles] = useState([]);
  const fileInput = useRef(null);

  let formData = new FormData();
  const onFileChange = (e) => {
    setNumFiles(e.target.files.length);
    setFiles([]);
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("files[]", e.target.files[i]);
      setFiles((oldArray) => [...oldArray, e.target.files[i]]);
      console.log(e.target.files[i]);
    }
  };

  return (
    <form>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Mensagens
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography variant="h6" className="title">
            Ver mensagem
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="subject"
                label="Assunto"
                inputProps={{ readOnly: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="address"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="address"
                label="Assunto"
                inputProps={{ readOnly: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="message"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="message"
                label="Mensagem"
                multiline
                rows={5}
                inputProps={{ readOnly: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} p={1} pb={5} align="left">
          <FormControl>
            <input
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              name="file"
              type="file"
              ref={fileInput}
              onChange={onFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button
                size="medium"
                variant="contained"
                component="span"
                color="warning"
              >
                {numfiles === 0
                  ? "Adicionar Ficheiros"
                  : numfiles === 1
                  ? 1 + " ficheiro adicionado"
                  : numfiles + " ficheiros adicionados"}
              </Button>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                pt={2}
              >
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.name}
                    icon={<DoneIcon />}
                    variant="outlined"
                    color="warning"
                  />
                ))}
              </Stack>
            </label>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
