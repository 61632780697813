import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function LiterarySkills({ users, literaries, updateuser }) {
  const auth = useAuthUser();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: users,
  });

  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(users);
    // console.log(literaries);
  }, [users, literaries]);

  const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/user`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        updateuser(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "As habilitações literárias foram guardadas com sucesso!";
  //

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} p={1} align="left">
            <Typography variant="h6" p={1} className="title">
              Habilitações Literárias
            </Typography>
          </Grid>

          <Grid item xs={12} p={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                Habilitações
              </InputLabel>
              <Controller
                control={control}
                name="USER_QUALIFICATION_ID"
                rules={{ required: "As habilitações são obrigatórias." }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Habilitações"
                    error={!!errors.USER_QUALIFICATION_ID}
                  >
                    {literaries.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.CODIGO_HABILITACAO}
                        {...(users.USER_QUALIFICATION_ID ===
                          item.CODIGO_HABILITACAO)}
                      >
                        {item.DESCRICAO}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <ErrorMessage
              errors={errors}
              name="USER_QUALIFICATION_ID"
              as="p"
              className="error"
            />
          </Grid>
          <Grid item xs={12} p={1}>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              className="btnPrimary"
            >
              Gravar
            </Button>
          </Grid>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </form>
  <BtnWhatsapp />
    </div>
  );
}