import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import CheckboxTree from "react-checkbox-tree";
import "../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/styles";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


const useStyles = makeStyles({
  // This group of buttons will be aligned to the right
  boxchoose: {
    border: "1px solid black",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    overflowY: "scroll",
  },
  label: {
    padding: "9px",
    fontFamily: "Arial",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    cursor: "pointer",
  },
});

export default function ProfessionaPreferences({
  categories,
  areas,
  hours,
  updatecategories,
  updateareas,
  updatehours,
}) {
  const classes = useStyles();
  const auth = useAuthUser();

  //Filter Default Checktree Values
  const getJobsSelectedWith1 = categories
    .map((x) => x.children)
    .flat()
    .filter((y) => {
      if (y.USER_JOB_CATEGORY_SELECTED === 1) {
        return y;
      }
    });

  const getJobsValueWith1 = getJobsSelectedWith1.map((y) => y.value.toString());
  //

  //Checkbox Tree
  const [checkedTree, setCheckedTree] = useState(getJobsValueWith1);
  const [expandedTree, setExpandedTree] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nodesFiltered, setNodesFiltered] = useState();

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const watchJob = watch(checkedTree, false);

  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(categories);
    // console.log(areas);
    // console.log(hours);
  }, [categories, areas, hours]);

  ///FILTER LOGIC /////
  const onFilterChange = (e) => {
    if (e.target.value.length >= 3) {
      setFilterText(e.target.value);
      if (e.target.value) {
        filterTree();
      }
    } else {
      setFilterText("");
    }
  };

  const filterTree = () => {
    // Reset nodes back to unfiltered state
    if (!filterText || filterText === "" || filterText.length === 0) {
      setNodesFiltered(categories);
      return;
    }

    const nodesFiltered = (categories) => {
      return categories.reduce(filterNodes, []);
    };

    setNodesFiltered(nodesFiltered);
  };

  const filterNodes = (filtered, category) => {
    const children = (category.children || []).reduce(filterNodes, []);
    if (
      // Node's label matches the search string
      category.label
        .toLocaleLowerCase()
        .indexOf(filterText.toLocaleLowerCase()) > -1 ||
      // Or a children has a matching category
      children.length
    ) {
      filtered.push({ ...category, ...(children.length && { children }) });
    }
    return filtered;
  };
  //

  const onSubmit = (data) => {
    (async () => {
      const [{ data: data1 }, { data: data2 }, { data: data3 }] =
        await Promise.all([
          axios.patch(
            `${process.env.REACT_APP_API}/jobcategories`,
            {
              data: {
                values0: "",
                values1: data["job"],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + auth().token,
              },
            }
          ),
          axios.patch(
            `${process.env.REACT_APP_API}/workingAreas`,
            data["area"],
            {
              headers: {
                Authorization: "Bearer " + auth().token,
              },
            }
          ),
          axios.patch(
            `${process.env.REACT_APP_API}/workingHours`,
            data["hour"],
            {
              headers: {
                Authorization: "Bearer " + auth().token,
              },
            }
          ),
        ]);
      // console.log(data1, data2, data3);
      updatecategories(data1);
      updateareas(data2);
      updatehours(data3);
      setAlert(true);
    })();
  };

  //Area Checked
  const [checkedArea, setCheckedArea] = useState(false);

  const handleChangeArea = () => {
    setCheckedArea(!checkedArea);
  };
  //

  //Hour Checked
  const [checkedHour, setCheckedHour] = useState(false);
  const handleChangeHour = () => {
    setCheckedHour(!checkedHour);
  };
  //

  //Validation
  const [savedAreas, setSavedAreas] = useState(Boolean);
  useEffect(() => {
    if (getValues("area").includes(true)) {
      setSavedAreas(false);
    } else {
      setSavedAreas(true);
    }
  });

  const [savedHours, setSavedHours] = useState(Boolean);
  useEffect(() => {
    if (getValues("hour").includes(true)) {
      setSavedHours(false);
    } else {
      setSavedHours(true);
    }
  });
  //

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "As preferências profissionais foram guardadas com sucesso!";
  //

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Preferências profissionais
          </Typography>
        </Grid>
        <Grid item xs={12} p={1} align="left">
          <Typography>Selecione a(s) Área(s) de interesse(s):</Typography>
          <FormGroup className={classes.boxchoose}>
            <div className="filter-container">
              <input
                className="filter-text"
                placeholder="Procurar..."
                type="text"
                onChange={onFilterChange}
              />
              <CheckboxTree
                nodes={filterText.length !== 0 ? nodesFiltered : categories}
                checked={checkedTree}
                expanded={expandedTree}
                onCheck={(checkedTree) => setCheckedTree(checkedTree)}
                onExpand={(expandedTree) => setExpandedTree(expandedTree)}
                {...register("job", {
                  required: "Pelo menos uma área de interesse é obrigatória.",
                  validate: (value) =>
                    value.length < 6 ||
                    "Só pode escolher entre 1 a 5 áreas de interesse.",
                })}
              />
            </div>
          </FormGroup>
          {errors.job && (
            <p className="error">{errors.job && errors.job.message}</p>
          )}
        </Grid>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Zonas geográficas de interesse
          </Typography>
        </Grid>
        <Grid item xs={12} p={1} align="left">
          <Typography>Selecione a(s) Zona(s) de interesse(s):</Typography>
          <FormGroup
            className={classes.boxchoose}
            sx={{
              height: {
                xs: "none",
                sm: "none",
                md: "262px",
                lg: "262px",
              },
            }}
          >
            <FormControl component="fieldset" style={{ display: "contents" }}>
              {areas.map((area) => (
                <label
                  key={area.USER_WORKING_AREAS_ID}
                  className={classes.label}
                >
                  <Controller
                    name={"area." + area.USER_WORKING_AREAS_ID}
                    defaultValue={area.USER_WORKING_AREAS_SELECTED}
                    control={control}
                    rules={{ required: savedAreas }}
                    render={({ field }) => (
                      <Checkbox
                        color="success"
                        onClick={handleChangeArea}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                  {area.USER_WORKING_AREAS_DESCRIPTION}
                </label>
              ))}
            </FormControl>
          </FormGroup>
          {savedAreas && (
            <ErrorMessage
              errors={errors}
              name={"area"}
              as="p"
              className="error"
              message="Pelo menos uma zona geográfica é obrigatória."
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Horários de prefência
        </Typography>
      </Grid>
      <Grid item xs={12} p={1}>
        <Grid item xs={12} p={1} align="left">
          <Typography>Selecione o(s) Horários(s) de preferência(s):</Typography>
          <FormGroup
            className={classes.boxchoose}
            sx={{
              height: {
                xs: "none",
                sm: "none",
                md: "142px",
                lg: "142px",
              },
            }}
          >
            <FormControl component="fieldset" style={{ display: "contents" }}>
              {hours.map((hour) => (
                <label
                  key={hour.USER_WORKING_HOURS_ID}
                  className={classes.label}
                >
                  <Controller
                    name={"hour." + hour.USER_WORKING_HOURS_ID}
                    defaultValue={hour.USER_WORKING_HOURS_SELECTED}
                    control={control}
                    rules={{ required: savedHours }}
                    render={({ field }) => (
                      <Checkbox
                        color="success"
                        onClick={handleChangeHour}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                  {hour.USER_WORKING_HOURS_DESCRIPTION}
                </label>
              ))}
            </FormControl>
          </FormGroup>
          {savedHours && (
            <ErrorMessage
              errors={errors}
              name={"hour"}
              as="p"
              className="error"
              message="Pelo menos um horário de prefência é obrigatória."
            />
          )}
        </Grid>
        <Grid item xs={12} pt={2}>
          <Button
            type="submit"
            value="submit"
            variant="contained"
            className="btnPrimary"
            onClick={() => {
              setValue("job", checkedTree);
            }}
          >
            Gravar
          </Button>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
<BtnWhatsapp />
    </div>
  );
}