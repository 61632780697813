import React from "react";
import Header from "../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <div>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        pb={4}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            marginTop: { xs: "15vh", sm: "35vh", md: "35vh", lg: "35vh" },
          }}
        >
          <Typography variant="h5" pb={3}>
            Página não encontrada!
          </Typography>
          <Divider />
          <Typography variant="h6" p={3}>
            Clique no botão para voltar para a <strong>Homepage!</strong>
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button variant="contained" className="btnPrimary">
              Home
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default NoMatch;
