import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import Alert from '@mui/material/Alert';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BtnWhatsapp from "./BtnWhatsapp";
export default function Personalnfo({
  users,
  countries,
  districts,
  updateuser,
}) {
  const auth = useAuthUser();
  const [counties, setCounties] = useState(null);
  const [county, setCounty] = useState("");
  const [parishes, setParishes] = useState([]);

  const {
    control,
    watch,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: users,
  });


  
  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(users);
    // console.log(countries);
    // console.log(districts);
  }, [users, countries, districts]);

  //Get Counties
  let watchDistrict = watch("USER_DISTRICT_ID");
  let watchCounty = watch("USER_COUNTY_ID");

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(
        `${process.env.REACT_APP_API}/counties?CODIGO_DISTRITO=${watchDistrict}`,
        {
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setCounties(res.data);
        //Default value para evitar Mui erro
        setCounty(watchCounty);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, [watchDistrict, watchCounty]);
  //
    //GET FREGUESIAS / LOCATIONS
    useEffect(() => {
      const abortCont = new AbortController();
      if (watchCounty && watchDistrict) {

        axios
          .get(
            `${process.env.REACT_APP_API}/locations?CODIGO_DISTRITO=${watchDistrict}&CODIGO_CONCELHO=${watchCounty}`,
            {
              signal: abortCont.signal,
            }
          )
          .then((res) => {
            setParishes(res.data);
            resetField("USER_LOCAL_ID");
          })
          .catch((err) => {
            // console.log(err);
          });
      }
  
      return () => abortCont.abort();
    }, [watchCounty]);

    const handleParishes = (event) => {
      setParishes(event.target.value);
    };

  const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/user`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        updateuser(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "As informações adicionais foram guardadas com sucesso!";
  //

  //Disable inputs
  const USER_STATUS = getValues("USER_STATUS");
  const [status, setStatus] = useState(true);
  const [exStatus, setExStatus] = useState(true);

  //USER_STATUS 0 não tem contrato 1 tem contrato

  useEffect(() => {
    if (USER_STATUS == 0) {
      setStatus(false);
    } else if (USER_STATUS == 1) {
      setStatus(true);
    } 
  }, [USER_STATUS]);
  console.log("USER_STATUS");
console.log(USER_STATUS);
  //Check if address is empty
  const USER_ADDRESS_1 = watch("USER_ADDRESS_1");
  const USER_ADDRESS_2 = watch("USER_ADDRESS_2");

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
      

        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Informação Pessoal
          </Typography>
        </Grid>
        {USER_STATUS == 1 &&
        <Grid item xs={12} pt={2} pb={2}>
            <Alert severity="info">Neste momento tem um contrato a decorrer. Não é possivel efectuar qualquer alteração na informação pessoal.</Alert>
        </Grid>
        }
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="USER_NAME"
            rules={{
              required: "O nome é obrigatório",
              pattern: {
                value: /^[A-Za-z\s\D]+$/i,
                message: "O nome deverá conter texto.",
              },
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="text"
                label="Nome completo"
                error={!!errors.USER_NAME}
                inputProps={{ readOnly: status }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="USER_NAME"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="USER_BIRTHDATE"
            rules={{
              required: "A data de nascimento é obrigatória.",
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                InputLabelProps={{ shrink: true }}
                fullWidth={true}
                id="outlined-required"
                type="date"
                label="Data de nascimento"
                error={!!errors.USER_BIRTHDATE}
                inputProps={{ readOnly: status }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="USER_BIRTHDATE"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="NIF_UTILIZADOR"
            rules={{
              required: "O número de contribuinte é obrigatório.",
              minLength: {
                value: 9,
                message:
                  "O número de contribuinte deverá conter no mínimo 9 elementos.",
              },
              maxLength: {
                value: 9,
                message:
                  "O número de contribuinte deverá conter no máximo 9 elementos.",
              },
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="number"
                label="Número de contribuinte"
                error={!!errors.NIF_UTILIZADOR}
                inputProps={{ readOnly: status }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="NIF_UTILIZADOR"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="EMAIL_UTILIZADOR"
            rules={{
              required: "O email é obrigatório.",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Não contém um endereço de email válido.",
              },
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="email"
                label="Endereço de Email"
                error={!!errors.EMAIL_UTILIZADOR}
                inputProps={{ readOnly: status && exStatus }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="EMAIL_UTILIZADOR"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="USER_TELEPHONE"
            rules={{
              required: "O número de contato é obrigatório.",
              minLength: {
                value: 9,
                message: "O contato deverá conter no mínimo 9 elementos.",
              },
              maxLength: {
                value: 9,
                message: "O contato deverá conter no máximo 9 elementos.",
              },
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="number"
                label="Número de contacto"
                error={!!errors.USER_TELEPHONE}
                inputProps={{ readOnly: status && exStatus }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="USER_WHATSAPP"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="USER_WHATSAPP"
            rules={{
              required: "O número de contato é obrigatório.",
              minLength: {
                value: 9,
                message: "O contato deverá conter no mínimo 9 elementos.",
              },
              maxLength: {
                value: 9,
                message: "O contato deverá conter no máximo 9 elementos.",
              },
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="number"
                label="Número de whatsapp"
                error={!!errors.USER_WHATSAPP}
                inputProps={{ readOnly: status && exStatus }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="USER_TELEPHONE"
            as="p"
            className="error"
          />
        </Grid>
        
        {USER_ADDRESS_1 !== null && (
          <Grid item xs={12} sm={6} p={1}>
            <Controller
              control={control}
              name="USER_ADDRESS_1"
              render={({ field }) => (
               <TextField
                disabled={status == 1 ? true : false }
                  {...field}
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Morada"
                  error={!!errors.USER_ADDRESS_1}
                  inputProps={{ readOnly: status && exStatus }}
                />
              )}
            />
          </Grid>
        )}
        {USER_ADDRESS_2 !== null && (
          <Grid item xs={12} sm={6} p={1}>
            <Controller
              control={control}
              name="USER_ADDRESS_2"
              render={({ field }) => (
               <TextField
                disabled={status == 1 ? true : false }
                  {...field}
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Localidade"
                  inputProps={{ readOnly: status && exStatus }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} p={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
            <Controller
              control={control}
              name="USER_SEX"
              rules={{ required: "O sexo é obrigatório." }}
              render={({ field }) => (
                <Select
                  disabled={status == 1 ? true : false }
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sexo"
                  error={!!errors.USER_SEX}
                  inputProps={{ readOnly: status }}
                >
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Feminino</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="USER_SEX"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <Controller
            control={control}
            name="USER_POSTAL_CODE"
            rules={{
              required: "O código postal é obrigatório.",
            }}
            render={({ field }) => (
             <TextField
                disabled={status == 1 ? true : false }
                {...field}
                fullWidth={true}
                id="outlined-required"
                type="text"
                label="Código postal"
                placeholder="_ _ _ _-_ _ _"
                error={!!errors.USER_POSTAL_CODE}
                inputProps={{ readOnly: status && exStatus }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="USER_POSTAL_CODE"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Nacionalidade</InputLabel>
            <Controller
              control={control}
              name="USER_COUNTRY_ID"
              rules={{ required: "A nacionalidade é obrigatória." }}
              render={({ field }) => (
                <Select
                  disabled={status == 1 ? true : false }
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Nacionalidade"
                  error={!!errors.USER_COUNTRY_ID}
                  inputProps={{ readOnly: status }}
                >
                  {countries.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.CODIGO_PAIS}
                      {...(users.USER_COUNTRY_ID === item.CODIGO_PAIS)}
                    >
                      {item.NACIONALIDADE}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="USER_COUNTRY_ID"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Distrito</InputLabel>
            <Controller
              control={control}
              name="USER_DISTRICT_ID"
              rules={{ required: "O distrito é obrigatório." }}
              render={({ field }) => (
                <Select
                  disabled={status == 1 ? true : false }
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Distrito"
                  error={!!errors.USER_DISTRICT_ID}
                  inputProps={{ readOnly: status && exStatus }}
                >
                  {districts.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.CODIGO_DISTRITO}
                      {...(users.USER_DISTRICT_ID === item.CODIGO_DISTRITO)}
                    >
                      {item.DESCRICAO}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="USER_DISTRICT_ID"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Concelho</InputLabel>
            <Controller
              control={control}
              name="USER_COUNTY_ID"
              rules={{ required: "O concelho é obrigatório." }}
              render={({ field }) => (
                <Select
                  disabled={status == 1 ? true : false }
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Concelho"
                  error={!!errors.USER_COUNTY_ID}
                  value={county}
                  inputProps={{ readOnly: status && exStatus }}
                >
                  {counties &&
                    counties.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.CODIGO_CONCELHO}
                        {...(users.USER_COUNTY_ID === item.CODIGO_CONCELHO)}
                      >
                        {item.DESCRICAO}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="USER_COUNTY_ID"
            as="p"
            className="error"
          />
        </Grid>

        <Grid item xs={12} sm={6} p={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Freguesia*
            </InputLabel>
            <Controller //parishes
              control={control}
              name="USER_LOCAL_ID"
              defaultValue=""
              onChange={handleParishes}
              rules={{ required: "O Freguesia é obrigatório." }}
              render={({ field }) => (
                <Select
                  disabled={status == 1 ? true : false }
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Freguesia"
                  error={!!errors.USER_LOCAL_ID}
                  inputProps={{ readOnly: status && exStatus }}
                >
                  {!watchCounty && (
                    <MenuItem value="">Escolha uma Concelho</MenuItem>
                  )}
                  {watchCounty &&
                    parishes.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.CODIGO_FREGUESIA}
                      >
                        {item.DESCRICAO}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="USER_LOCAL_ID"
            as="p"
            className="error"
          />
        </Grid>

        <Grid item xs={12} sm={6} p={1}>
            <Controller
              control={control}
              name="USER_PAYMENT_TYPE"
              render={({ field }) => (
               <TextField
                disabled={status == 1 ? true : false }
                  {...field}
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Método de Pagamento"
                  error={!!errors.USER_PAYMENT_TYPE}
                  inputProps={{ readOnly: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} p={1}>
            <Controller
              control={control}
              name="USER_IBAN"
              render={({ field }) => (
               <TextField
                disabled={status == 1 ? true : false }
                  {...field}
                  fullWidth={true}
                  id="outlined-required"
                  type="TEXT"
                  label="IBAN"
                  error={!!errors.USER_IBAN}
                  inputProps={{ readOnly: true }}
                />
              )}
            />
          </Grid>
        <Grid item xs={12} p={1}>
          <Button
            disabled={status == 1 ? true : false }
            type="submit"
            value="submit"
            variant="contained"
            className="btnPrimary"
          >
            Gravar
          </Button>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
      <BtnWhatsapp />
    </div>
  );
}