import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import Header from "../components/Header";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import WarningIcon from "@mui/icons-material/Warning";
import { ErrorMessage } from "@hookform/error-message";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import FormGroup from "@mui/material/FormGroup";
import CheckboxTree from "react-checkbox-tree";
import "../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import ErrorIcon from "@mui/icons-material/Error";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold !important",
    color: "#48aa37",
  },
  title2: {
    fontWeight: "bold !important",
    color: "#48aa37",
    fontStyle: "italic",
  },
  accordion: {
    backgroundColor: "#48aa37 !important",
  },
  accordionTitle: {
    color: "#fff",
  },
  iconColor: {
    color: "#fff",
  },
  boxchoose: {
    border: "1px solid black",
    padding: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    overflowY: "scroll",
  },
  label: {
    padding: "9px",
    fontFamily: "Arial",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    cursor: "pointer",
  },
  titleBold: {
    fontWeight: "bold !important",
  },
  errors: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "10px",
  },
  errorIcon: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    paddingRight: "0px",
  },
});

export default function Register(props) {
  const classes = useStyles();

  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [literaries, setLiteraries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [hours, setHours] = useState([]);

  const {
    register,
    watch,
    control,
    resetField,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      jobs_array: [
        {
          USER_EXPERIENCE_COMPANY: "",
          USER_EXPERIENCE_FUNCTION: "",
          USER_EXPERIENCE_FROM_DATE: "",
          USER_EXPERIENCE_TO_DATE: "",
        },
      ],
      qualifications_array: [
        {
          USER_QUALIFICATION_SCHOOL: "",
          USER_QUALIFICATION_DESCRIPTION: "",
          USER_QUALIFICATION_DURATION: "",
        },
      ],
      inexperienced: false,
      USER_HAS_DRIVERS_LICENCE: "3",
      USER_HAS_CAR: "3",
      USER_COMMUNICATION_TYPE: "",
      USER_NEWSLETTER: false,
      USER_PRIVACY_POLICY: false,
    },
  });

  

    //Array de fields
    const { fields: qualificationsFields, append: qualificationsAppend, remove: qualificationsRemove } = useFieldArray({
      control,
      name: "qualifications_array", // unique name for your Field Array
    });

    const watchCompany = watch("qualifications_array.0.USER_QUALIFICATION_SCHOOL");

  
  //Get Countries
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/countries`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setCountries(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);

  const handleCountries = (event) => {
    setCountries(event.target.value);
  };
  //

  //Get Districts
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/districts`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setDistricts(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);

  const handleDistricts = (event) => {
    setDistricts(event.target.value);
  };
  
  //Get Counties
  let watchDistrict = watch("USER_DISTRICT_ID");
  let watchCounty = watch("USER_COUNTY_ID");

  useEffect(() => {
    const abortCont = new AbortController();

    if (watchDistrict) {
      axios
        .get(
          `${process.env.REACT_APP_API}/counties?CODIGO_DISTRITO=${watchDistrict}`,
          {
            signal: abortCont.signal,
          }
        )
        .then((res) => {
          // console.log(res.data);
          setCounties(res.data);
          //Colocar o concelho vazio quando o distrito é mudado
          resetField("USER_COUNTY_ID");
          resetField("USER_LOCAL_ID");
        })
        .catch((err) => {
          // console.log(err);
        });
    }

    return () => abortCont.abort();
  }, [watchDistrict]);

  //GET FREGUESIAS / LOCATIONS
  useEffect(() => {
    const abortCont = new AbortController();
    if (watchCounty && watchDistrict) {
      axios
        .get(
          `${process.env.REACT_APP_API}/locations?CODIGO_DISTRITO=${watchDistrict}&CODIGO_CONCELHO=${watchCounty}`,
          {
            signal: abortCont.signal,
          }
        )
        .then((res) => {
          console.log(res.data);
          setParishes(res.data);
          //Colocar o concelho vazio quando o distrito é mudado
          resetField("USER_LOCAL_ID");
        })
        .catch((err) => {
          // console.log(err);
        });
    }

    return () => abortCont.abort();
  }, [watchCounty]);


  const handleCounties = (event) => {
    setCounties(event.target.value);
  };

  const handleParishes = (event) => {
    setParishes(event.target.value);
  };

  //Array de fields para a experiência profissional
  const { fields, append, remove } = useFieldArray({
    control,
    name: "jobs_array", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  //Disable Fields on Profissional Experience
  const [checked, setChecked] = useState(false);
  const [isRequired, setIsRequired] = useState(true);

  const handleChangeInexperienced = () => {
    setChecked(!checked);
    //Muda o required para true ou false
    setIsRequired(!isRequired);
    //Reset aos fields do jobs_array
    setValue("jobs_array", [
      {
        USER_EXPERIENCE_COMPANY: "",
        USER_EXPERIENCE_FUNCTION: "",
        USER_EXPERIENCE_FROM_DATE: "",
        USER_EXPERIENCE_TO_DATE: "",
      },
    ]);
  };
  //

  //Get Literaries
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/skills`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setLiteraries(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);

  const handleLiteraries = (event) => {
    setLiteraries(event.target.value);
  };
  //

  //Get Idiomas
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/abilities`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setLanguages(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Jobs Categories
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/jobcategoriesPublic`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setCategories(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Areas
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/workingAreasPublic`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setAreas(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Hours
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/workingHoursPublic`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setHours(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  //Area Checked
  const [checkedArea, setCheckedArea] = useState(false);

  const handleChangeArea = () => {
    setCheckedArea(!checkedArea);
  };
  //

  //Hour Checked
  const [checkedHour, setCheckedHour] = useState(false);

  const handleChangeHour = () => {
    setCheckedHour(!checkedHour);
  };
  //

  //Validation
  const [display, setDisplay] = useState(false);

  const [savedAreas, setSavedAreas] = useState(Boolean);
  useEffect(() => {
    if (getValues("area")) {
      if (getValues("area").includes(true)) {
        setSavedAreas(false);
      } else {
        setSavedAreas(true);
      }
    }
  });

  const [savedHours, setSavedHours] = useState(Boolean);

  useEffect(() => {
    if (getValues("hour")) {
      if (getValues("hour").includes(true)) {
        setSavedHours(false);
      } else {
        setSavedHours(true);
      }
    }
  });

  //Marketing Checked
  const [checkedMarketing, setCheckedMarketing] = useState(false);

  const handleChangeMarketing = () => {
    setCheckedMarketing(!checkedMarketing);
  };
  //

  //Terms Checked
  const [checkedTerms, setCheckedTerms] = useState(false);

  const handleChangeTerms = () => {
    setCheckedTerms(!checkedTerms);
  };
  //

  //FormData
  let formData = new FormData();

  //CV File
  const [cvfile, setCvfile] = useState();
  const [isRequiredFile, setIsRequiredFile] = useState(true);

  const onFileChange = (e) => {
    setCvfile();
    if (e.target.files.length !== null) {
      setCvfile(e.target.files[0]);
      setIsRequiredFile(false);
    } else {
      setCvfile(null);
      setIsRequiredFile(true);
    }
  };

  //Checkbox Tree
  const [checkedTree, setCheckedTree] = useState([]);
  const [expandedTree, setExpandedTree] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nodesFiltered, setNodesFiltered] = useState();

  ///FILTER LOGIC /////
  const onFilterChange = (e) => {
    if (e.target.value.length >= 3) {
      setFilterText(e.target.value);
      if (e.target.value) {
        filterTree();
      }
    } else {
      setFilterText("");
    }
  };

  const filterTree = () => {
    // Reset nodes back to unfiltered state
    if (!filterText || filterText === "" || filterText.length === 0) {
      setNodesFiltered(categories);
      return;
    }

    const nodesFiltered = (categories) => {
      return categories.reduce(filterNodes, []);
    };

    setNodesFiltered(nodesFiltered);
  };

  const filterNodes = (filtered, category) => {
    const children = (category.children || []).reduce(filterNodes, []);
    if (
      // Node's label matches the search string
      category.label
        .toLocaleLowerCase()
        .indexOf(filterText.toLocaleLowerCase()) > -1 ||
      // Or a children has a matching node
      children.length
    ) {
      filtered.push({ ...category, ...(children.length && { children }) });
    }
    return filtered;
  };
  //

  //Navigate para Ativate page
  const navigate = useNavigate();


  const watchJob = watch(checkedTree, false);

  //console.log(errors);

  const onSubmit = (data) => {


    //File
    formData.append("NIF_UTILIZADOR", data.NIF_UTILIZADOR);
    formData.append("EMAIL_UTILIZADOR", data.EMAIL_UTILIZADOR);
    formData.append("PASS_UTILIZADOR", data.PASS_UTILIZADOR);
    formData.append("USER_ATTACHMENT_CLASS_ID ", 500);
    formData.append("USER_ATTACHMENT", cvfile);

    console.log("data");
    console.log(data);

    axios
      .post(`${process.env.REACT_APP_API}/user`, data)
      .then((res) => {
        if (res.status === 200) {
          axios.post(
            `${process.env.REACT_APP_API}/usernewattachmentpublic`,
            formData
          );
        }
        // link para ativate page when success
        navigate("ativate", {
          state: { EMAIL_UTILIZADOR: data.EMAIL_UTILIZADOR },
        });
      })
      .catch((err) => {
        console.log("errror");
        if (err.response && err.response.status === 400) {
          alert(err.response.data);
        }
      });
  };

  function validateNIF(value) {
    const nif = typeof value === 'string' ? value : value.toString();
    const validationSets = {
      one: ['1', '2', '3', '5', '6', '8'],
      two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
    };
    if (nif.length !== 9) return false;
    if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) return false;
    const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
    const modulo11 = (Number(total) % 11);
    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
    return checkDigit === Number(nif[8]);
  }


  return (
    <div>
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        pb={4}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
            marginTop: { xs: "10vh", sm: "15vh", md: "15vh", lg: "15vh" },
          }}
        >
          <Typography variant="h5" pb={2} className={classes.title}>
            REGISTO DE CANDIDATO
          </Typography>
          <Typography variant="subtitle2" pb={2}>
            Preencha o seguinte formulário para ter acesso às candidaturas e
            outras funcionalidades de recrutamento. Os campos com (*) são de
            preenchimento obrigatório.
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            sx={{
              paddingBottom: { xs: "50px", sm: "50px", md: "0px", lg: "0px" },
              paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
              paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
            }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    1. INFORMAÇÃO PESSOAL
                  </Typography>
                  {(errors.USER_NAME ||
                    errors.USER_BIRTHDATE ||
                    errors.NIF_UTILIZADOR ||
                    errors.PASS_UTILIZADOR ||
                    errors.PASS_UTILIZADOR_repeat ||
                    errors.EMAIL_UTILIZADOR ||
                    errors.USER_TELEPHONE ||
                    errors.USER_WHATSAPP ||
                    errors.USER_SEX ||
                    errors.USER_POSTAL_CODE ||
                    errors.USER_COUNTRY_ID ||
                    errors.USER_DISTRICT_ID ||
                    errors.USER_COUNTY_ID  || 
                    errors.USERS_PARISH_ID) && (
                    <ErrorIcon color="error" className={classes.errorIcon} />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="text"
                    label="Nome completo*"
                    error={!!errors.USER_NAME}
                    {...register("USER_NAME", {
                      required: "O nome é obrigatório",
                      pattern: {
                        value: /^[A-Za-z\s\D]+$/i,
                        message: "O nome deverá conter texto.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="USER_NAME"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    type="date"
                    label="Data de nascimento*"
                    error={!!errors.USER_BIRTHDATE}
                    {...register("USER_BIRTHDATE", {
                      required: "A data de nascimento é obrigatória.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="USER_BIRTHDATE"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="number"
                    label="Número de contribuinte*"
                    error={!!errors.NIF_UTILIZADOR}
                    {...register("NIF_UTILIZADOR", {
                      required: "O número de contribuinte é obrigatório.",
                      minLength: {
                        value: 9,
                        message:
                          "O número de contribuinte deverá conter no mínimo 9 digitos.",
                      },
                      maxLength: {
                        value: 9,
                        message:
                          "O número de contribuinte deverá conter no máximo 9 digitos.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="NIF_UTILIZADOR"
                    as="p"
                    className="error"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="email"
                    label="Endereço de Email*"
                    error={!!errors.EMAIL_UTILIZADOR}
                    {...register("EMAIL_UTILIZADOR", {
                      required: "O email é obrigatório.",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Não contém um endereço de email válido.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="EMAIL_UTILIZADOR"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="number"
                    label="Número de telemovel*"
                    error={!!errors.USER_TELEPHONE}
                    {...register("USER_TELEPHONE", {
                      required: "O número de contato é obrigatório.",
                      minLength: {
                        value: 9,
                        message:
                          "O contato deverá conter no mínimo 9 elementos.",
                      },
                      maxLength: {
                        value: 9,
                        message:
                          "O contato deverá conter no máximo 9 elementos.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="USER_TELEPHONE"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="number"
                    label="Número de Whatsapp"
                    error={!!errors.USER_WHATSAPP}
                    {...register("USER_WHATSAPP", {
                      minLength: {
                        value: 9,
                        message:
                          "O contato deverá conter no mínimo 9 elementos.",
                      },
                      maxLength: {
                        value: 9,
                        message:
                          "O contato deverá conter no máximo 9 elementos.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="USER_WHATSAPP"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Sexo*</InputLabel>
                    <Select
                      fullWidth={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sexo*"
                      defaultValue=""
                      error={!!errors.USER_SEX}
                      {...register("USER_SEX", {
                        required: "O sexo é obrigatório.",
                      })}
                    >
                      <MenuItem value="M">Masculino</MenuItem>
                      <MenuItem value="F">Feminino</MenuItem>
                    </Select>
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_SEX"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth={true}
                    type="text"
                    label="Código postal*"
                    placeholder="_ _ _ _-_ _ _"
                    error={!!errors.USER_POSTAL_CODE}
                    {...register("USER_POSTAL_CODE", {
                      required: "O código postal é obrigatório.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="USER_POSTAL_CODE"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Nacionalidade*
                    </InputLabel>
                    <Controller
                      control={control}
                      name="USER_COUNTRY_ID"
                      defaultValue=""
                      onChange={handleCountries}
                      rules={{ required: "A nacionalidade é obrigatória." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Nacionalidade"
                          error={!!errors.USER_COUNTRY_ID}
                        >
                          {countries.map((item, index) => (
                            <MenuItem key={index} value={item.CODIGO_PAIS}>
                              {item.NACIONALIDADE}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_COUNTRY_ID"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Distrito*
                    </InputLabel>
                    <Controller
                      control={control}
                      name="USER_DISTRICT_ID"
                      defaultValue=""
                      onChange={handleDistricts}
                      rules={{ required: "O distrito é obrigatório." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Distrito"
                          error={!!errors.USER_DISTRICT_ID}
                        >
                          {districts.map((item, index) => (
                            <MenuItem key={index} value={item.CODIGO_DISTRITO}>
                              {item.DESCRICAO}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_DISTRICT_ID"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Concelho*
                    </InputLabel>
                    <Controller
                      control={control}
                      name="USER_COUNTY_ID"
                      defaultValue=""
                      onChange={handleCounties}
                      rules={{ required: "O concelho é obrigatório." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Concelho"
                          error={!!errors.USER_COUNTY_ID}
                        >
                          {!watchDistrict && (
                            <MenuItem value="">Escolha um distrito</MenuItem>
                          )}
                          {watchDistrict &&
                            counties.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.CODIGO_CONCELHO}
                              >
                                {item.DESCRICAO}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_COUNTY_ID"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Freguesia*
                    </InputLabel>
                    <Controller //parishes
                      control={control}
                      name="USER_LOCAL_ID"
                      defaultValue=""
                      onChange={handleParishes}
                      rules={{ required: "O Freguesia é obrigatório." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Freguesia"
                          error={!!errors.USER_LOCAL_ID}
                        >
                          {!watchCounty && (
                            <MenuItem value="">Escolha uma Concelho</MenuItem>
                          )}
                          {watchCounty &&
                            parishes.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.CODIGO_FREGUESIA}
                              >
                                {item.DESCRICAO}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_LOCAL_ID"
                    as="p"
                    className="error"
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    2. EXPERIÊNCIA PROFISSIONAL
                  </Typography>
                  {(errors?.jobs_array?.[fields.length - 1]
                    ?.USER_EXPERIENCE_COMPANY ||
                    errors?.jobs_array?.[fields.length - 1]
                      ?.USER_EXPERIENCE_FUNCTION ||
                    errors?.jobs_array?.[fields.length - 1]
                      ?.USER_EXPERIENCE_FROM_DATE) && (
                    <ErrorIcon color="error" className={classes.errorIcon} />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                {fields.map((field, index) => {
                  return (
                    <Grid container key={field.id}>
                      <Grid item xs={12} pl={1}>
                        {index > 0 && (
                          <Typography align="left">
                            <WarningIcon /> Nova experiência profissional!
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} p={1}>
                        <TextField
                          fullWidth={true}
                          type="text"
                          label="Empresa"
                          disabled={checked}
                          // //Rever
                          // error={!!errors.jobs_array}
                          {...register(
                            `jobs_array.${index}.USER_EXPERIENCE_COMPANY`,
                            {
                              required: isRequired,
                            }
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`jobs_array[${index}].USER_EXPERIENCE_COMPANY`}
                          as="p"
                          className="error"
                          message="A experiência profissional é obrigatória."
                        />
                      </Grid>
                      <Grid item xs={12} p={1}>
                        <TextField
                          fullWidth={true}
                          type="text"
                          multiline
                          rows={4}
                          label="Funções"
                          disabled={checked}
                          {...register(
                            `jobs_array.${index}.USER_EXPERIENCE_FUNCTION`,
                            {
                              required: isRequired,
                            }
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`jobs_array[${index}].USER_EXPERIENCE_FUNCTION`}
                          as="p"
                          className="error"
                          message="A função é obrigatória."
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} p={1}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          type="date"
                          label="Data início"
                          disabled={checked}
                          {...register(
                            `jobs_array.${index}.USER_EXPERIENCE_FROM_DATE`,
                            {
                              required: isRequired,
                            }
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`jobs_array[${index}].USER_EXPERIENCE_FROM_DATE`}
                          as="p"
                          className="error"
                          message="A data inicial é obrigatória."
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} p={1}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          type="date"
                          label="Data fim"
                          disabled={checked}
                          {...register(
                            `jobs_array.${index}.USER_EXPERIENCE_TO_DATE`
                          )}
                        />
                        <FormHelperText
                          id="component-helper-text"
                          sx={{ paddingLeft: "2px;" }}
                        >
                          Não preencher caso ainda esteja em funções
                        </FormHelperText>
                      </Grid>
                      <input
                        type="hidden"
                        {...register(`jobs_array.${index}.JOB_EXPERIENCE_ID`)}
                        value={
                          field.USER_EXPERIENCE_COMPANY +
                          "-" +
                          field.USER_EXPERIENCE_FROM_DATE
                        }
                      />
                      <input
                        type="hidden"
                        {...register(`jobs_array.${index}.JOB_EXPERIENCE_ID`)}
                        value=""
                      />
                      <Grid container p={1} className="pe-DeleteBtn">
                        {index > 0 && (
                          <Button
                            variant="contained"
                            onClick={() => remove(index)}
                            color="error"
                          >
                            Eliminar
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} pl={2}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="inexperienced"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            color="success"
                            onClick={handleChangeInexperienced}
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}
                            label="Checkbox with label"
                          />
                        )}
                      />
                    }
                    label="Sem experiência profissional"
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <Button
                    p={1}
                    onClick={() =>
                      append({
                        JOB_EXPERIENCE_ID: null,
                        USER_EXPERIENCE_TO_DATE: null,
                      })
                    }
                    variant="contained"
                    className="fr"
                    color="primary"
                  >
                    Adicionar Mais
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    3. HABILITAÇÕES LITERÁRIAS
                  </Typography>
                  {(errors.USER_QUALIFICATION_ID || errors.language) && (
                    <ErrorIcon color="error" className={classes.errorIcon} />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid item xs={12} sm={6} p={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Habilitações*
                    </InputLabel>
                    <Controller
                      control={control}
                      name="USER_QUALIFICATION_ID"
                      defaultValue=""
                      onChange={handleLiteraries}
                      rules={{ required: "As habilitações são obrigatórias." }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Habilitações"
                          error={!!errors.USER_QUALIFICATION_ID}
                        >
                          {literaries.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.CODIGO_HABILITACAO}
                            >
                              {item.DESCRICAO}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="USER_QUALIFICATION_ID"
                    as="p"
                    className="error"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        id="tags-outlined"
                        options={languages}
                        getOptionLabel={(option) => option.DESCRICAO}
                        isOptionEqualToValue={(option, value) =>
                          option.CODIGO_CONHECIMENTO ===
                          value.CODIGO_CONHECIMENTO
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Idiomas"
                            placeholder="Escolher"
                            error={!!errors.language}
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                    control={control}
                    name="language"
                    defaultValue={[]}
                    rules={{ required: "O idioma é obrigatório." }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="language"
                    as="p"
                    className="error"
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    4. FORMAÇÃO PROFISSIONAL
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
              {qualificationsFields.map((field, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12} pl={1}>
                        {index > 0 && (
                          <Typography align="left">
                            <WarningIcon /> Nova formação profissional!
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={5} p={1}>
                        <TextField
                          fullWidth={true}
                          id="outlined-required"
                          type="text"
                          label="Designação da Formação Profissional"
                          // Rever
                          // error={!!errors.qualifications_array}
                          {...register(`qualifications_array.${index}.USER_QUALIFICATION_DESCRIPTION`, {
                            required: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`qualifications_array[${index}].USER_QUALIFICATION_DESCRIPTION`}
                          as="p"
                          className="error"
                          message="A Designação da Formação Profissional é obrigatória."
                        />
                      </Grid>
                      
                      <Grid item xs={12} sm={5} p={1}>
                        <TextField
                          fullWidth={true}
                          id="outlined-required"
                          type="text"
                          label="Entidade formadora"
                          // Rever
                          // error={!!errors.qualifications_array}
                          {...register(`qualifications_array.${index}.USER_QUALIFICATION_SCHOOL`, {
                            required: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`qualifications_array[${index}].USER_QUALIFICATION_SCHOOL`}
                          as="p"
                          className="error"
                          message="A Entidade formadora Profissional é obrigatória."
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} p={1}>
                        <TextField
                          fullWidth={true}
                          id="outlined-required"
                          type="text"
                          label="Duração(Horas)"
                          {...register(`qualifications_array.${index}.USER_QUALIFICATION_DURATION`, {
                            required: true,
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`qualifications_array[${index}].USER_QUALIFICATION_DURATION`}
                          as="p"
                          className="error"
                          message="A duração é obrigatória."
                        />
                      </Grid>

                      <input
                        type="hidden"
                        {...register(`qualifications_array.${index}.USER_QUALIFICATION_ID`)}
                        value={
                          field.USER_QUALIFICATION_DESCRIPTION +
                          "-" +
                          field.USER_EXPERIENCE_FUNCTION
                        }
                      />
                      <Grid container p={1} className="pe-DeleteBtn">
                        
                          <Button
                            variant="contained"
                            onClick={(event) => {
                              qualificationsRemove(index);
                            }}
                            color="error"
                          >
                            Eliminar
                          </Button>
                       
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} p={1}>
                  <Button
                    p={1}
                    onClick={() =>
                      qualificationsAppend({ USER_QUALIFICATION_ID: null, USER_QUALIFICATION_DESCRIPTION: null })
                    }
                    variant="contained"
                    className="fr"
                    color="primary"
                  >
                    Adicionar Mais
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    5. PREFERÊNCIAS PROFISSIONAIS
                  </Typography>
                  {((!watchJob.length && errors.job) ||
                    savedAreas === true ||
                    savedHours === true) &&
                    display && (
                      <ErrorIcon color="error" className={classes.errorIcon} />
                    )}
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid item xs={12} p={1} align="left">
                  <Typography>
                    Selecione cinco áreas de interesse :
                  </Typography>
                  <FormGroup className={classes.boxchoose}>
                    <div className="filter-container">
                      <input
                        className="filter-text"
                        placeholder="Procurar..."
                        type="text"
                        onChange={onFilterChange}
                      />
                      <CheckboxTree
                        nodes={
                          filterText.length !== 0 ? nodesFiltered : categories
                        }
                        checked={checkedTree}
                        expanded={expandedTree}
                        onCheck={(checkedTree) => setCheckedTree(checkedTree)}
                        onExpand={(expandedTree) =>
                          setExpandedTree(expandedTree)
                        }
                        {...register("job", {
                          required:
                            "Pelo menos uma área de interesse é obrigatória.",
                          validate: (value) =>
                            value.length < 6 ||
                            "Só pode escolher entre 1 a 5 áreas de interesse.",
                        })}
                      />
                    </div>
                  </FormGroup>
                  {errors.job && (
                    <p className="error">{errors.job && errors.job.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} p={1} align="left">
                  <Typography>
                    Selecione a(s) Zona(s) de interesse(s):
                  </Typography>
                  <FormGroup
                    className={classes.boxchoose}
                    sx={{
                      height: {
                        xs: "none",
                        sm: "none",
                        md: "262px",
                        lg: "262px",
                      },
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ display: "contents" }}
                    >
                      {areas.map((area) => (
                        <label
                          key={area.USER_WORKING_AREAS_ID}
                          className={classes.label}
                        >
                          <Controller
                            name={"area." + area.USER_WORKING_AREAS_ID}
                            defaultValue={area.USER_WORKING_AREAS_SELECTED}
                            control={control}
                            rules={{ required: savedAreas }}
                            render={({ field }) => (
                              <Checkbox
                                color="success"
                                onClick={handleChangeArea}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          />
                          {area.USER_WORKING_AREAS_DESCRIPTION}
                        </label>
                      ))}
                    </FormControl>
                  </FormGroup>
                  {savedAreas && (
                    <ErrorMessage
                      errors={errors}
                      name={"area"}
                      as="p"
                      className="error"
                      message="Pelo menos uma zona geográfica é obrigatória."
                    />
                  )}
                </Grid>
                <Grid item xs={12} p={1} align="left">
                  <Typography>
                    Selecione o(s) Horários(s) de preferência(s):
                  </Typography>
                  <FormGroup
                    className={classes.boxchoose}
                    sx={{
                      height: {
                        xs: "none",
                        sm: "none",
                        md: "142px",
                        lg: "142px",
                      },
                    }}
                  >
                    <FormControl
                      component="fieldset"
                      style={{ display: "contents" }}
                    >
                      {hours.map((hour) => (
                        <label
                          key={hour.USER_WORKING_HOURS_ID}
                          className={classes.label}
                        >
                          <Controller
                            name={"hour." + hour.USER_WORKING_HOURS_ID}
                            defaultValue={hour.USER_WORKING_HOURS_SELECTED}
                            control={control}
                            rules={{ required: savedHours }}
                            render={({ field }) => (
                              <Checkbox
                                color="success"
                                onClick={handleChangeHour}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                checked={field.value}
                              />
                            )}
                          />
                          {hour.USER_WORKING_HOURS_DESCRIPTION}
                        </label>
                      ))}
                    </FormControl>
                  </FormGroup>
                  {savedHours && (
                    <ErrorMessage
                      errors={errors}
                      name={"hour"}
                      as="p"
                      className="error"
                      message="Pelo menos um horário de prefência é obrigatória."
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.accordion}
              >
                <div className={classes.errors}>
                  <Typography className={classes.accordionTitle}>
                    6. INFORMAÇÕES ADICIONAIS
                  </Typography>

                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid item xs={12} sm={6} p={1} align="left">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Tem carta de condução válida
                    </FormLabel>
                    <Controller
                      aria-label="cartaconducao"
                      name="USER_HAS_DRIVERS_LICENCE"
                      control={control}
                      render={({ field }) => {
                        return (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="success" />}
                              label="Não"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="success" />}
                              label="Sim"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} p={1} align="left">
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Tem viatura própria
                    </FormLabel>
                    <Controller
                      aria-label="viaturapropria"
                      name="USER_HAS_CAR"
                      defaultValue=""
                      control={control}
                      render={({ field }) => {
                        return (
                          <RadioGroup {...field}>
                            <FormControlLabel
                              value="0"
                              control={<Radio color="success" />}
                              label="Não"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio color="success" />}
                              label="Sim"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} p={1}>
                  <Controller
                    name="cvfile"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        accept=".pdf,.docx,.doc,image/jpeg"
                        type="file"
                        id="raised-button-file"
                        style={{ display: "none" }}
                        className="fr"
                        onChange={onFileChange}
                      />
                    )}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      p={1}
                      variant="contained"
                      component="span"
                      className="fr"
                      color="primary"
                    >
                      ANEXAR CV
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  {errors.cvfile &&
                    errors.cvfile.type === "required" &&
                    isRequiredFile === true && (
                      <p className="error fr">
                        Erro ao adicionar o ficheiro, verifique o tipo ou se foi
                        adicionado correctamente.
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} pr={1}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    pt={1}
                    className="fr"
                  >
                    {cvfile !== undefined && (
                      <Chip
                        label={cvfile.name}
                        icon={<DoneIcon />}
                        color="primary"
                        variant="outlined"
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} p={1}>
                  <Typography variant="subtitle2" className="cvSubtitleRight">
                    O ficheiro deve ser inferior a 5MB e apenas serão aceites
                    ficheiros com as extensões pdf, docx, doc ou jpg/png.
                  </Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid container pt={2}>
            <Grid item xs={12} md={6} >
                <TextField
                  fullWidth={true}
                  type="password"
                  label="Password*"
                  error={!!errors.PASS_UTILIZADOR}
                  {...register("PASS_UTILIZADOR", {
                    required: "A password é obrigatória.",
                    minLength: {
                      value: 8,
                      message:
                        "A password deverá conter no mínimo 8 elementos.",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="PASS_UTILIZADOR"
                  as="p"
                  className="error"
                />
            </Grid>

             <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    type="password"
                    label="Repetir password*"
                    error={!!errors.PASS_UTILIZADOR_repeat}
                    {...register("PASS_UTILIZADOR_repeat", {
                      validate: (value) => {
                        if (value === getValues()["PASS_UTILIZADOR"]) {
                          return true;
                        } else {
                          return "As passwords devem ser iguais.";
                        }
                      },
                    })}
                  />                  
                </Grid>
            </Grid>
            

                <ErrorMessage
                    errors={errors}
                    name="PASS_UTILIZADOR_repeat"
                    as="p"
                    className="error"
                  />
                <p style={{ fontSize: "10px" }}>
                  Introduza uma password com mais de 8 caracteres composta por letras maiúsculas e minúsculas, algarismos e pelo menos um caracter especial.
                  </p>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              paddingTop: { xs: "0px", sm: "0px", md: "50px" },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              sx={{
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              <Typography pb={2} align="left">
              Autorizo que os meus dados pessoais contidos neste formulário e/ou outros documentos submetidos por mim, sejam objeto de tratamento pelo Grupo SW (Success Work – Empresa de Trabalho Temporário, Lda., Success Work Services – Prestação de Serviços, Lda. e Success Work Move – Rent a Car, Lda.) para as seguintes finalidades:
              </Typography>
              <Typography
                pb={2}
                align="left"
                className={classes.titleBold}
              >
              Participação e envio de comunicações referentes a processos de recrutamento e seleção (escolha apenas uma das opções):
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              align="left"
              sx={{
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              <FormControl component="fieldset">
                <Controller
                  aria-label="communication"
                  name="USER_COMMUNICATION_TYPE"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="0"
                          control={<Radio color="success" />}
                          label="De oportunidades de emprego sugeridas pela Success Work ou da minha candidatura a anúncios de emprego divulgados pelo  Portal (workers.swork.pt)"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio color="success" />}
                          label="Unicamente de candidaturas a anúncios de emprego divulgados no Portal (workers.swork.pt)"
                          sx={{
                            paddingTop: { xs: "20px", sm: "20px", md: "20px" },
                          }}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
              <ErrorMessage
                errors={errors}
                name="USER_COMMUNICATION_TYPE"
                as="p"
                className="error"
                message="Escolha uma das opções de cima."
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              align="left"
              sx={{
                paddingTop: { xs: "20px", sm: "20px", md: "20px" },
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              <FormControlLabel
                control={
                  <Controller
                    name="USER_NEWSLETTER"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        color="success"
                        onClick={handleChangeMarketing}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        label="Checkbox with label"
                      />
                    )}
                  />
                }
                label="Ações de marketing para divulgação de notícias, eventos e promoção de serviços do Grupo SW."
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              align="left"
              sx={{
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              <Typography pt={2} pb={2}>
              O consentimento prestado para o tratamento de dados pessoais pelo titular de dados pessoais, poderá ser revogado por si, a todo o momento. Para revogar o consentimento do tratamento dos seus dados pessoais para as finalidades acima enunciadas, ou para exercer os seus direitos de acesso, retificação, oposição, apagamento, limitação e portabilidade, por favor, consulte a nossa política de privacidade.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              align="left"
              sx={{
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              <FormControlLabel
                control={
                  <Controller
                    name="USER_PRIVACY_POLICY"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Checkbox
                        color="success"
                        onClick={handleChangeTerms}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                        label="Checkbox with label"
                      />
                    )}
                  />
                }
                label={
                  <label>
                    Aceito a{" "}
                    <Link to="/" className="linkHome">
                      <b>Política de Privacidade</b>
                    </Link>{" "}
                    do Grupo SW
                  </label>
                }
              />
              <ErrorMessage
                errors={errors}
                name="USER_PRIVACY_POLICY"
                as="p"
                className="error"
                message="É necessário aceitar a política de privacidade."
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              pt={2}
              pb={5}
              align="left"
              sx={{
                paddingTop: { xs: "20px", sm: "30px" },
                paddingLeft: { xs: "50px", sm: "50px", md: "50px" },
                paddingRight: { xs: "50px", sm: "50px", md: "50px" },
              }}
            >
              
              <Button
                type="submit"
                value="submit"
                variant="contained"
                className="btnPrimary"
                size="large"
                onClick={() => {
                  setValue("job", checkedTree);
                  setDisplay(true);
                }}
              >
                REGISTAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}