import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ApiAlert = () => {
  return (
    <div>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Informação não disponível!
        </Typography>
      </Grid>
      <Alert severity="error">
        <AlertTitle>Erro</AlertTitle>
        Não é você, somos nós —{" "}
        <strong>Aguarde uns minutos antes de tentar de novo!</strong>
      </Alert>
    </div>
  );
};

export default ApiAlert;
