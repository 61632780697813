import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@mui/material/styles";
import { useAuthUser } from "react-auth-kit";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AdjustIcon from '@mui/icons-material/Adjust';
import BoltIcon from '@mui/icons-material/Bolt';

const useStyles = makeStyles({
  listItem: {
    display: "inline-grid !important",
  },
  font12: {
    fontSize: "12px !important",
  },
  font13: {
    top: "5px !important",
    position: "relative"
  },
  linkall:{
    textAlign: "right",
    color: "white",
    textDecoration: "none !important"
  }
});

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
function getLocalOfferFormat(distrito, concelho, freguesia, local){
  if(local !== null)  return local;
  if(freguesia !== null) return freguesia;
  if(concelho !== null) return concelho;
  if(distrito !== null) return distrito;

  return "";
}
export default function Home(props) {
  const classes = useStyles();
  const open = props.open;
  const auth = useAuthUser();

  //Get Offers
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/offer`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setOffers(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  return (
    <div className={!open ? "divCenter" : ""}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="xsMax320"
        sx={{
          marginTop: { xs: "0px", sm: "25vh", md: "30vh", lg: "30vh" },
          marginLeft: { xs: "0vh", sm: "-5vh", md: "-3vh" },
          paddingTop: "0px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} className="nameHome">
          <Typography className="typographyLeft">Olá,</Typography>
          <Typography className="typographyLeft">
            {auth().user.USER_NAME}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <Typography pb={1}>
            <b>O que vamos fazer hoje?</b>
          </Typography>
          <ListItem className={classes.listItem}>
      
            <Link to="/dashboard/messages/received" className="linkHome">
              <Stack spacing={1} direction="row">
                <ListItemText primary="Ver as minhas mensagens" />
                <Badge badgeContent={0} color="success">
                  <MailIcon color="action" />
                </Badge>
              </Stack>
            </Link> 
 
            <Link to="/dashboard/view/schedules" className="linkHome">
              <ListItemText primary="Ver as minhas escalas de trabalho" />
            </Link>
            <Link to="/dashboard/view/applications" className="linkHome">
              <ListItemText primary="Consultar histórico de candidaturas e emprego" />
            </Link>
          </ListItem>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex" }}>
        
        <CssBaseline />
        <AppBar
          className="appBarHome"
          open={props.open}
          sx={{
            top: "auto",
            backgroundColor: "#48aa37",
            padding: "20px",
          }}
        >
          <Toolbar>
            <Grid container alignItems="flex-top">
              <Grid item xs={8} sm={6}>
                {offers.length > 0 && (
                  <Typography variant="h5" pl="8px">
                    Ofertas em destaque
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4} sm={6}>
                <Link to="/">
                  <ListItemText primary="Ver todas" className={classes.linkall} />
                </Link>
              </Grid>
              {offers.slice(0, 4).map((offer) => (
                <Grid item xs={6} sm={6} md={3}>
                  <ListItem className={classes.listItem} key={offer.OFFER_ID}>
                    <Link
                      to={`/offer/${offer.OFFER_ID}`}
                      rel="noopener noreferrer"
                      className="linkHomeWhite"
                    >
                    <ListItemText variant="h6" primary={offer.OFFER_AD_TITLE ? offer.OFFER_AD_TITLE : offer.OFFER_JOB} />
                   
                    <Typography className={classes.font12}>
                      <FmdGoodIcon className={classes.font13} fontSize="small" />  {getLocalOfferFormat(offer.OFFER_AD_DISTRITO,offer.OFFER_AD_CONCELHO, offer.OFFER_AD_FREGUESIA, offer.OFFER_AD_LOCAL)}
                    </Typography>
                    {offer.OFFER_JOB_GROUP &&
                    <Typography className={classes.font12}>
                      <BoltIcon className={classes.font13} fontSize="small" />{offer.OFFER_JOB_GROUP}
                    </Typography>
                    }
                    {offer.WORKING_HOURS &&
                    <Typography className={classes.font12}>
                      <AdjustIcon className={classes.font13} fontSize="small" />{offer.WORKING_HOURS}
                    </Typography>
                    }
                    </Link>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
  <BtnWhatsapp /> 
    </div>
  );
}
