import React, { useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useIsAuthenticated } from "react-auth-kit";
import { useAuthUser } from 'react-auth-kit';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import axios from "axios";


export default function NewMessage() {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();

  //Get date
  const current = new Date();
  const date = `${current.getFullYear()}/${String(
    current.getMonth() + 1
  ).padStart(2, "0")}/${String(current.getDate()).padStart(2, "0")} ${String(
    current.getHours()
  ).padStart(2, "0")}:${String(current.getMinutes()).padStart(2, "0")}:${String(
    current.getSeconds()
  ).padStart(2, "0")}`;
  //

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API}/messages`, data, 
        {headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAlert(true);
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const [popup, setPopUp] = useState(false);
  const message = "A mensagem foi enviada com sucesso!";
  const messageDraft = "A mensagem foi guardada com sucesso!";
  //

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Mensagens
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography variant="h6" className="title">
            Nova mensagem
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="MESSAGE_SUBJECT"
            defaultValue=""
            rules={{
              required: "O assunto é obrigatório",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="MESSAGE_SUBJECT"
                label="Assunto"
                error={!!errors.subject}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="subject"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="MESSAGE_TEXT"
            defaultValue=""
            rules={{
              required: "A mensagem é obrigatório",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="MESSAGE_TEXT"
                label="Mensagem"
                multiline
                rows={5}
                error={!!errors.message}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="message"
            as="p"
            className="error"
          />
        </Grid>

        <Grid item xs={12} p={1} align="left">
          <Button
            type="submit"
            value="submit"
            variant="contained"
            className="mr-10 mb-20 btnPrimary"
          >
            <SendIcon /> Enviar
          </Button>

          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
  );
}
