import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BtnWhatsapp from "../components/BtnWhatsapp";

export default function MedicineWork({ medicines }) {
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Medicina no Trabalho
        </Typography>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={medicines}
          getRowId={(row) => row.MEDICINE_ID}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "MEDICINE_DATE",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  { field: "MEDICINE_ID", hide: true },
  { field: "MEDICINE_TYPE", headerName: "Tipo", width: 50, flex: 1 },
  { field: "MEDICINE_DATE", headerName: "Data", width: 100, flex: 1 },
  { field: "MEDICINE_RESULT", headerName: "Resultado", minWidth: 130, flex: 1 },
];
