import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridToolbarColumnsButton, ptBR } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import BtnWhatsapp from "../components/BtnWhatsapp";


const useStyles = makeStyles({
  toolbarColumn: {
    "& .MuiButton-root": {
      paddingTop: "15px",
      paddingLeft: "18px",
    },
  },
});

//RenderCellExpand
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width: 200 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string,
};
//

export default function TimeSheets({ timesheets }) {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  //Filters
  const handleSearchMonth = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchYear = (e) => {
    setSearch(e.target.value);
  };

  const filtered = !search
    ? timesheets
    : timesheets.filter(
      (row) =>
        month[new Date(row.TIMESHEET_DATE_TIME_IN).getMonth()].includes(
          search
        ) ||
        [new Date(row.TIMESHEET_DATE_TIME_IN).getFullYear()].includes(search)
    );

  const clearFilter = (e) => {
    setSearch("");
  };
  //

  //Hide Years Duplicates
  var years = timesheets.map((row) =>
    new Date(row.TIMESHEET_DATE_TIME_IN).getFullYear()
  );
  var newYearsArray = years.filter(function (elem, pos) {
    return years.indexOf(elem) === pos;
  });
  //

  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Folhas de Ponto
        </Typography>
      </Grid>
        <Grid item xs={6} sm={6} md={3} p={1} align="center">
          <Select
            style={{ "width": "100%"}}
            labelId="yearSelectLabel"
            id="yearSelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchYear}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Ano</em>
            </MenuItem>
            {newYearsArray.map((item, index) => (
              <MenuItem key={index} value={item} className="company">
                <span className="company">{item}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} sm={6} md={3} p={1} align="center">
          <Select
            style={{ "width": "100%"}}
            labelId="monthSelectLabel"
            id="monthSelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchMonth}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Mês</em>
            </MenuItem>
            <MenuItem value="JAN">Janeiro</MenuItem>
            <MenuItem value="FEV">Fevereiro</MenuItem>
            <MenuItem value="MAR">Março</MenuItem>
            <MenuItem value="ABR">Abril</MenuItem>
            <MenuItem value="MAI">Maio</MenuItem>
            <MenuItem value="JUN">Junho</MenuItem>
            <MenuItem value="JUL">Julho</MenuItem>
            <MenuItem value="AGO">Agosto</MenuItem>
            <MenuItem value="SET">Setembro</MenuItem>
            <MenuItem value="OUT">Outubro</MenuItem>
            <MenuItem value="NOV">Novembro</MenuItem>
            <MenuItem value="DEZ">Dezembro</MenuItem>
          </Select>
        </Grid>


    
      <Grid p={1} sx={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={filtered}
          getRowId={(row) => row.TIMESHEET_ID}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "TIMESHEET_ID",
                  sort: "desc",
                },
              ],
            },
          }}
          className={classes.toolbarColumn}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  {
    field: "TIMESHEET_ID",
    headerName: "Id",
    width: 50,
    flex: 1,
    hide: true,
  },
  {
    field: "TIMESHEET_DATE_TIME_IN",
    headerName: "Entrada",
    type: "date",
    width: 100,
    flex: 1,
  },
  {
    field: "TIMESHEET_CLIENT",
    headerName: "Local de trabalho",
    width: 130,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    field: "TIMPSHEET_CATEGORY",
    headerName: "Categoria",
    width: 130,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    field: "TIMESHEET_DESCRIPTION",
    headerName: "Descrição",
    width: 130,
    flex: 1,
    hide: true,
  },
  {
    field: "TIMESHEET_TOTAL_HOURS",
    headerName: "Total de Horas",
    width: 130,
    flex: 1,
  },
  {
    field: "TIMESHEET_UNIT_TYPE",
    headerName: "Tipo",
    width: 130,
    flex: 1,
    hide: true,
  },
];

let month = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];