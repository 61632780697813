import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import FormHelperText from "@mui/material/FormHelperText";
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function ProfessionalFormation({ qualifications, updatequalifications }) {
  const auth = useAuthUser();

  console.log(qualifications);
  const {
    register,
    watch,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  //Array de fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: "qualifications_array", // unique name for your Field Array
  });

  //Default values
  useEffect(() => {
    reset({
      qualifications_array: qualifications,
    });
  }, [reset, qualifications]);

  const watchCompany = watch("qualifications_array.0.USER_QUALIFICATION_SCHOOL");

  const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/qualifications`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        updatequalifications(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Delete Job
  const handleDelete = (event, index) => {
    const watchJobId = watch(`qualifications_array.${index}.USER_QUALIFICATION_ID`);

    axios
      .delete(
        `${process.env.REACT_APP_API}/qualifications?USER_QUALIFICATION_ID=${watchJobId}`,
        {
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        console.log("Qualitifications deleted");
        updatequalifications(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      }); 
  };
  //

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "A formações profissionais foram atualizadas com sucesso!";

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Formação Profissional
          </Typography>
        </Grid>
        {fields.map((field, index) => {
          return (
            <Grid container key={index}>
              <Grid item xs={12} pl={1}>
                {!watchCompany && index === 0 && (
                  <Typography align="left">
                    <WarningIcon /> Verificamos que ainda não adicionou a sua formação profissional!
                  </Typography>
                )}
                {index > 0 && (
                  <Typography align="left">
                    <WarningIcon /> Nova formação profissional!
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={5} p={1}>
                <TextField
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Designação da Formação Profissional"
                  // Rever
                  // error={!!errors.qualifications_array}
                  {...register(`qualifications_array.${index}.USER_QUALIFICATION_DESCRIPTION`, {
                    required: true,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={`qualifications_array[${index}].USER_QUALIFICATION_DESCRIPTION`}
                  as="p"
                  className="error"
                  message="A Designação da Formação Profissional é obrigatória."
                />
              </Grid>
              
              <Grid item xs={12} sm={5} p={1}>
                <TextField
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Entidade formadora"
                  // Rever
                  // error={!!errors.qualifications_array}
                  {...register(`qualifications_array.${index}.USER_QUALIFICATION_SCHOOL`, {
                    required: true,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={`qualifications_array[${index}].USER_QUALIFICATION_SCHOOL`}
                  as="p"
                  className="error"
                  message="A Entidade formadora Profissional é obrigatória."
                />
              </Grid>
              <Grid item xs={12} sm={2} p={1}>
                <TextField
                  fullWidth={true}
                  id="outlined-required"
                  type="text"
                  label="Duração(Horas)"
                  {...register(`qualifications_array.${index}.USER_QUALIFICATION_DURATION`, {
                    required: true,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name={`qualifications_array[${index}].USER_QUALIFICATION_DURATION`}
                  as="p"
                  className="error"
                  message="A duração é obrigatória."
                />
              </Grid>

              <input
                type="hidden"
                {...register(`qualifications_array.${index}.USER_QUALIFICATION_ID`)}
                value={
                  field.USER_QUALIFICATION_DESCRIPTION +
                  "-" +
                  field.USER_EXPERIENCE_FUNCTION
                }
              />
              <Grid container p={1} className="pe-DeleteBtn">
                {index > 0 && (
                  <Button
                    variant="contained"
                    onClick={(event) => {
                      handleDelete(event, index);
                      remove(index);
                    }}
                    color="error"
                  >
                    Eliminar
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12} p={1}>
          <Button
            type="submit"
            value="submit"
            variant="contained"
            className="btnPrimary"
          >
            Gravar
          </Button>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
          <Button
            p={1}
            onClick={() =>
              append({ USER_QUALIFICATION_ID: null, USER_QUALIFICATION_DESCRIPTION: null })
            }
            variant="contained"
            className="fr"
            color="primary"
          >
            Adicionar Mais
          </Button>
        </Grid>
      </Grid>
    </form>
<BtnWhatsapp />
    </div>
  );
}