import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

export default function EditMessage({ popup, alert }) {
  const [messageEdit, setMessageEdit] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: messageEdit,
  });

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`http://localhost:8000/messages/${id}`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        // console.log(res.data);
        setMessageEdit(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, [id]);
  //

  //Default values quando faz refresh da página
  useEffect(() => {
    reset(messageEdit);
  }, [reset, messageEdit]);
  //

  const [numfiles, setNumFiles] = useState(0);
  const [files, setFiles] = useState([]);
  const fileInput = useRef(null);

  let formData = new FormData();
  const onFileChange = (e) => {
    setNumFiles(e.target.files.length);
    setFiles([]);
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("files[]", e.target.files[i]);
      setFiles((oldArray) => [...oldArray, e.target.files[i]]);
      console.log(e.target.files[i]);
    }
  };

  //Get date
  const current = new Date();
  const date = `${String(current.getDate()).padStart(2, "0")}/${String(
    current.getMonth() + 1
  ).padStart(2, "0")}/${current.getFullYear()} ${String(
    current.getHours()
  ).padStart(2, "0")}:${String(current.getMinutes()).padStart(2, "0")}:${String(
    current.getSeconds()
  ).padStart(2, "0")}`;
  //

  const onSubmit = (data) => {
    axios
      .patch(`http://localhost:8000/messages/${id}`, data)
      .then((res) => {
        console.log(res.data);
        alert(true);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const message = "A mensagem foi enviada com sucesso!";
  const messageDraft = "A mensagem foi guardada com sucesso!";
  const messageDel = "A mensagem foi apagada com sucesso!";
  //

  //Delete Message
  const handleClick = () => {
    alert("Tem a certeza que deseja remover a mensagem?");
    axios
      .delete(`http://localhost:8000/messages/${id}`)
      .then((res) => {
        console.log("Message deleted");
        alert(true);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Mensagens
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography variant="h6" className="title">
            Editar mensagem
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            rules={{
              required: "O assunto é obrigatório",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="subject"
                label="Assunto"
                error={!!errors.subject}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="subject"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Destinatário</InputLabel>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{ required: "A destinatário é obrigatório" }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="address"
                  label="Destinatário"
                  error={!!errors.address}
                >
                  <MenuItem value={"Agência A"}>Agência A</MenuItem>
                  <MenuItem value={"Agência B"}>Agência B</MenuItem>
                  <MenuItem value={"Agência C"}>Agência C</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="address"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="message"
            defaultValue=""
            rules={{
              required: "A mensagem é obrigatório",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="message"
                label="Mensagem"
                multiline
                rows={5}
                error={!!errors.message}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="message"
            as="p"
            className="error"
          />
        </Grid>
        <Grid item xs={12} p={1} pb={5} align="left">
          <FormControl>
            <input
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              name="file"
              type="file"
              ref={fileInput}
              onChange={onFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button
                size="medium"
                variant="contained"
                component="span"
                color="warning"
              >
                {numfiles === 0
                  ? "Adicionar Ficheiros"
                  : numfiles === 1
                  ? 1 + " ficheiro adicionado"
                  : numfiles + " ficheiros adicionados"}
              </Button>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                pt={2}
              >
                {files.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.name}
                    icon={<DoneIcon />}
                    variant="outlined"
                    color="warning"
                  />
                ))}
              </Stack>
            </label>
          </FormControl>
          <input type="hidden" value={date} {...register("date")} />
          <input type="hidden" value={1} {...register("sender")} />
        </Grid>
        <Grid item xs={12} p={1} align="left">
          <Button
            type="submit"
            variant="contained"
            className="mr-10 mb-20 btnPrimary"
            onClick={() => {
              setValue("type", "sent");
              popup(message);
            }}
          >
            <SendIcon /> Enviar
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="mr-10 mb-20"
            color="info"
            onClick={() => {
              setValue("type", "draft");
              popup(messageDraft);
            }}
          >
            <SaveIcon /> Editar rascunho
          </Button>
          <Button
            variant="contained"
            className="mr-10 mb-20"
            color="error"
            onClick={() => {
              handleClick();
              popup(messageDel);
            }}
          >
            <DeleteIcon /> Eliminar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
