import * as React from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PageviewIcon from "@mui/icons-material/Pageview";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

function DashboardMenu(props) {
  const [openinfo, setOpenInfo] = React.useState(false);
  const [openmsg, setOpenMsg] = React.useState(false);
  const [openconsult, setOpenConsult] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickInfo = () => {
    setOpenInfo(!openinfo);
  };

  const handleClickMsg = () => {
    setOpenMsg(!openmsg);
  };

  const handleClickConsult = () => {
    setOpenConsult(!openconsult);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const ListItem = withStyles({
    root: {
      "&": {
        color: "#231f20",
      },
      "&:hover": {
        backgroundColor: "#0000000a",
      },
      "&$selected": {
        color: "#48aa37",
        backgroundColor: "transparent",
      },
    },
    selected: {},
  })(MuiListItem);

  return (
    <div>
      <List className="dashList">
        <ListItem
          component={Link}
          to="/dashboard/home"
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        <ListItemButton onClick={handleClickInfo}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary="Informação"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
          {openinfo ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openinfo} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to="/dashboard/user/infopersonal"
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemText
                primary="Informação Pessoal"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/experience"
              sx={{ pl: 4 }}
              selected={selectedIndex === 21}
              onClick={(event) => handleListItemClick(event, 21)}
            >
              <ListItemText
                primary="Experiência Profissional"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/formation"
              sx={{ pl: 4 }}
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemText
                primary="Formação Profissional"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/literaryskills"
              sx={{ pl: 4 }}
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemText
                primary="Habilitações Literárias"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/idiomas"
              sx={{ pl: 4 }}
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              <ListItemText
                primary="Idiomas"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/addinfo"
              sx={{ pl: 4 }}
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemText
                primary="Informações adicionais"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/prefprof"
              sx={{ pl: 4 }}
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              <ListItemText
                primary="Preferências Profissionais"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>

            <ListItem
              component={Link}
              to="/dashboard/user/attachfiles"
              sx={{ pl: 4 }}
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
            >
              <ListItemText
                primary="Anexar Ficheiros"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickMsg}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary="Mensagens"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
          {openmsg ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openmsg} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to="/dashboard/messages/new"
              sx={{ pl: 4 }}
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
            >
              <ListItemText
                primary="Nova Mensagem"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/messages/received"
              sx={{ pl: 4 }}
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
            >
              <ListItemText
                primary="Recebidas"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/messages/send"
              sx={{ pl: 4 }}
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
            >
              <ListItemText
                primary="Enviadas"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickConsult}>
          <ListItemIcon>
            <PageviewIcon />
          </ListItemIcon>
          <ListItemText
            primary="Consultar"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
          {openconsult ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openconsult} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to="/dashboard/view/schedules"
              sx={{ pl: 4 }}
              selected={selectedIndex === 12}
              onClick={(event) => handleListItemClick(event, 12)}
            >
              <ListItemText
                primary="Escalas de trabalho"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/applications"
              sx={{ pl: 4 }}
              selected={selectedIndex === 13}
              onClick={(event) => handleListItemClick(event, 13)}
            >
              <ListItemText
                primary="Histórico de candidaturas"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/contracts"
              sx={{ pl: 4 }}
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
            >
              <ListItemText
                primary="Contratos"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/receipts"
              sx={{ pl: 4 }}
              selected={selectedIndex === 15}
              onClick={(event) => handleListItemClick(event, 15)}
            >
              <ListItemText
                primary="Recibos"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/timesheets"
              sx={{ pl: 4 }}
              selected={selectedIndex === 16}
              onClick={(event) => handleListItemClick(event, 16)}
            >
              <ListItemText
                primary="Folhas de ponto"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/medicinework"
              sx={{ pl: 4 }}
              selected={selectedIndex === 17}
              onClick={(event) => handleListItemClick(event, 17)}
            >
              <ListItemText
                primary="Medicina no Trabalho"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/view/irs"
              sx={{ pl: 4 }}
              selected={selectedIndex === 18}
              onClick={(event) => handleListItemClick(event, 18)}
            >
              <ListItemText
                primary="Declarações de IRS"
                sx={{ fontWeight: "bold", pl: 4 }}
                disableTypography
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          component={Link}
          to="/dashboard/settings"
          selected={selectedIndex === 19}
          onClick={(event) => handleListItemClick(event, 19)}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Configurações"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        <a href="https://swork.pt/" className="linkHome">
          <ListItem className="mt-25">
            <ListItemIcon>
              <KeyboardBackspaceIcon />
            </ListItemIcon>
            <ListItemText primary="Voltar ao site" />
          </ListItem>
        </a>
      </List>
    </div>
  );
}

export default DashboardMenu;
