import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Header from "../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useSignIn } from "react-auth-kit";
import { useIsAuthenticated } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold !important",
    color: "#48aa37",
  },
  title2: {
    fontWeight: "bold !important",
    color: "#48aa37",
    fontStyle: "italic",
  },
});

function Login() {
  const classes = useStyles();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });


  //Error Login
  const [error, setError] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [msg, setMsg] = useState("");
  const [msgative, setMsgAtive] = useState(false);

  const onSubmit = (data) => {
    axios
      .post(`${process.env.REACT_APP_API}/auth/login`, data)
      .then((res) => {
        if (res.status === 201 && res.data.user.VALIDADO === "S") {
          if (
            signIn({
              token: res.data.token,
              expiresIn: 120,
              tokenType: "Bearer",
              authState: res.data,
              // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
              // refreshTokenExpireIn: res.data.refreshTokenExpireIn,
            })
          ) {
            // Only if you are using refreshToken feature
            // Redirect or do-something
            setError(false);
            navigate("/dashboard/home");
          } 
        }else if( res.data.user.VALIDADO === "N"){
          setMsg("Ainda não ativou a sua conta, após o registo enviamos e-mail com link para ativação.");
          setMsgAtive(true);
        }else{
          setMsg("Por favor contacte a administração do sistema, há alguma dificuldade.");
          setMsgAtive(true);

        }
      })
      .catch((err) => {
        //console.log(err.response.data.message);
        setError(true);
        setErrorLogin("NIF ou Password inválida");
      });
  };

  return (
    <div>
      <Header />
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          paddingLeft: { xs: "5px", sm: "50px", md: "50px", lg: "0px" },
          paddingRight: { xs: "5px", sm: "50px", md: "50px", lg: "0px" },
          marginTop: { xs: "10vh", sm: "30vh", md: "20vh", lg: "10vh" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className="nameHome"
          sx={{
            padding: { xs: "50px", sm: "50px", md: "0px", lg: "100px" },
          }}
        >
          <Typography variant="h5" pb={2} className={classes.title}>
            Bem-vindo ao portal de emprego da SW!
          </Typography>
          <Typography variant="subtitle1">
            A SW renovou o seu portal de emprego para ajudá-lo a encontrar novas
            oportunidades.
          </Typography>
          <Typography variant="subtitle2" pb={2}>
            Por favor, registe-se para ter acesso.
          </Typography>
          <Typography variant="h6" pb={3} className={classes.title2}>
            <Link to="/register" className="linkHome title">
              Registe-se agora
            </Link>
          </Typography>

          {error && (
            <Grid item p={1}>
              <Alert severity="error">{errorLogin}</Alert>
            </Grid>
          )}
          { msgative && (
             <Alert severity="error">{msg}</Alert>
          )}
         

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth={true}
                id="outlined-nif-required"
                type="number"
                label="NIF"
                placeholder="Insira o número de contribuinte"
                error={!!errors.NIF_UTILIZADOR}
                {...register("NIF_UTILIZADOR", {
                  required: "O número de contribuinte é obrigatório.",
                  minLength: {
                    value: 9,
                    message:
                      "O número de contribuinte deverá conter no mínimo 9 elementos.",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "O número de contribuinte deverá conter no máximo 9 elementos.",
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="NIF_UTILIZADOR"
                as="p"
                className="error"
              />
            </Grid>

            <Grid item xs={12} p={1}>
              <TextField
                fullWidth={true}
                id="outlined-password-required"
                type="password"
                label="Palavra-passe"
                placeholder="Insira a sua password"
                error={!!errors.PASS_UTILIZADOR}
                {...register("PASS_UTILIZADOR", {
                  required: "A palavra-passe é obrigatória.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="PASS_UTILIZADOR"
                as="p"
                className="error"
              />
            </Grid>

            <Grid item xs={12} p={1}>
              <Button
                fullWidth={true}
                type="submit"
                value="submit"
                variant="contained"
                className="btnPrimary"
              >
                Login
              </Button>

              <a
                href="https://api.swork.pt/password/reset"
                target="_blank"
                rel="noopener noreferrer"
                className="linkHome"
              >
                <Typography variant="subtitle2" pt={1}>
                  Recuperar a palavra-passe
                </Typography>
              </a>
            </Grid>
          </form>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className="bckgirl"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
        >
        
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;