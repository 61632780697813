import React, { useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ReceiveMessage({ messages, updatemessage }) {
  const hasFetchedData = useRef(false);

  

  //Default values quando faz refresh da página
  useEffect(() => {
    if (!hasFetchedData.current) {
  
      updatemessage(messages);
      hasFetchedData.current = true;
    }
  }, [messages, updatemessage]);
  
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Mensagens Recebidas
        </Typography>
      </Grid>
      {messages === undefined || messages === null ? 
        <Grid item mt={5}>
          <Typography variant="span">
            Sem mensagens recebidas
          </Typography>
        </Grid> 
        : 
        <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={messages}
          getRowId={(row) => row.MESSAGE_ID }
          columns={columns}
          pageSize={5}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "date",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
        }
      
    </Grid>
  );
}

const columns = [
  { field: "MESSAGE_ID", headerName: "id", hide: true },
  { field: "MESSAGE_DATE_TIME", headerName: "Data", minWidth: 90, flex: 1 },
  { field: "MESSAGE_SUBJECT", headerName: "Assunto", minWidth: 130, flex: 1 },
  { field: "MESSAGE_TEXT", headerName: "Mensagem", minWidth: 250, flex: 1, cellClassName:'tablecellmsg' },
];
