import React, { useState, useEffect } from "react";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default function NewsletterForm() {

    const url = "https://swork.us7.list-manage.com/subscribe/post?u=2f28c362a5c09ec63388ebc1f&amp;id=b843fc375c&amp;f_id=00bb80e3f0";
    const { loading, error, success, message, handleSubmit } = useMailChimpForm(
        url
    );
    const { fields, handleFieldChange } = useFormFields({
        EMAIL: "",
        newsletter_checkbox: false,
    });
    return (
        <div>
            <Grid container>
                <form onSubmit={event => {
                        event.preventDefault();
                        handleSubmit(fields);
                    }}>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="h6" className="typoFooter">
                        Fique a par de todas as novidades, ofertas de emprego e tendências do mercado de trabalho
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} pt={2}>
                        <input
                            className="inputnewsletter"
                            id="EMAIL"
                            type="email"
                            value={fields.EMAIL}
                            onChange={handleFieldChange}
                            placeholder="E-mail"
                        />
                    </Grid>
                    <Grid item xs={12} sm={10} pt={2}>
                    <input
                        type="checkbox"
                        id="newsletter_checkbox"
                        value={fields.newsletter_checkbox}
                        onChange={handleFieldChange}
                        required
                    />
                    <label for="html">Aceito que os meus dados sejam utilizados para envio de notícias, eventos, ações promocionais e divulgação de novos serviços.</label>
                    </Grid>
                    <Grid item xs={12} sm={10} pt={2}>
                    <input
                        type="checkbox"
                        id="newsletter_checkbox2"
                        value={fields.newsletter_checkbox}
                        onChange={handleFieldChange}
                        required
                    />
                    <label htmlFor="newsletter_checkbox"> Li e aceito a <a href="https://swork.pt/politica-da-privacidade/">Política de Privacidade</a> e <a href="https://swork.pt/termos-e-condicoes/">Termos e Condições</a> do website.</label>
                    </Grid>
                    <Grid item xs={12} sm={7} pt={2}>
                        <button className="btnOutlined">Quero receber novidades</button>
                    </Grid>
                </form>
                {error &&    
                    <Alert severity="error">
                        <AlertTitle>Erro</AlertTitle>
                        <strong>Aguarde uns minutos antes de tentar de novo!</strong>
                    </Alert>
                }

                {success &&
                    <Alert severity="success">
                        <AlertTitle>E-mail submetido com sucesso</AlertTitle>
                    </Alert>
                }
                </Grid>
        </div>
    );
}
