import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function AplicationsH({ recruitments }) {
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Histórico de Candidaturas
        </Typography>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={recruitments}
          getRowId={(row) => row.USER_RECRUTMENT_ID}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'USER_RECRUTMENT_DATE',
                  sort: 'desc',
                },
              ],
            },
          }}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  { field: "MEDICINE_ID", hide: true },
  { field: "USER_RECRUTMENT_DATE", headerName: "Data", width: 100, flex: 1 },
  { field: "USER_RECRUTMENT_REF", headerName: "Referência", minWidth: 130, flex: 1 },
  { field: "USER_RECRUTMENT_CATEGORY", headerName: "Descritivo", minWidth: 130, flex: 1 },
  {
    field: "USER_RECRUTMENT_LOCAL",
    headerName: "Local de trabalho",
    minWidth: 130,
    flex: 1,
  },
];