import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListOffer from "../components/ListOffer";
import Footer from "../components/Footer";
import PageviewIcon from "@mui/icons-material/Pageview";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";

export default function Homepage(props) {
  //Get Offers
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/offer`, { signal: abortCont.signal })
      .then((res) => {
        // console.log(res.data);
        setOffers(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, []);
  //

  //Hide Duplicates
  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const uniqueJob = getUnique(offers, "OFFER_JOB_ID");
  const uniqueGroup = getUnique(offers, "RECRUITMENT_GROUP");
  //

  //Filter Data
  const [valueJob, setValueJob] = useState("");
  const [inputValueJob, setInputValueJob] = useState("");
  const [valueGroup, setValueGroup] = useState(null);
  const [inputValueGroup, setInputValueGroup] = useState("");

  return (
    <div>
      <Header />
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "50px" },
          marginTop: { xs: "10vh", sm: "30vh", md: "35vh", lg: "30vh" },
          textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          className="nameHome"
          sx={{
            padding: { xs: "20px", sm: "0px", md: "0px", lg: "100px" },
          }}
        >
          <Typography
            variant="h3"
            pb={2}
            className="titleBold"
            sx={{
              fontSize: { xs: "2.5rem", sm: "2.5rem", md: "3rem", lg: "3rem" },
            }}
          >
            Agarre o mundo de oportunidades que temos para si
          </Typography>
          <form>
            <Grid
              item
              pt={5}
              alignItems="center"
              sx={{
                display: { xs: "block", sm: "block", md: "block", lg: "flex" },
              }}
            >
              <Autocomplete
                value={valueJob}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setValueJob("");
                  } else {
                    setValueJob(newValue.OFFER_JOB_ID);
                  }
                }}
                inputValue={inputValueJob}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setInputValueJob("");
                  } else {
                    setInputValueJob(newInputValue);
                  }
                }}
                id="controllable-states-demo"
                options={uniqueJob}
                getOptionLabel={(option) => option.OFFER_JOB || inputValueJob}
                isOptionEqualToValue={(option, value) =>
                  option.OFFER_JOB_ID === value.OFFER_JOB_ID
                }
                sx={{
                  width: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "300px",
                  },
                  paddingRight: {
                    xs: "0px",
                    sm: "0px",
                    md: "10px",
                    lg: "10px",
                  },
                  paddingBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "0px",
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Função ou Setor" />
                )}
              />
              <Autocomplete
                value={valueGroup}
                onChange={(event, newValue) => {
                  setValueGroup(newValue);
                }}
                inputValue={inputValueGroup}
                onInputChange={(event, newInputValue) => {
                  setInputValueGroup(newInputValue);
                }}
                id="controllable-states-demo"
                options={uniqueGroup}
                getOptionLabel={(option) => option.RECRUITMENT_GROUP}
                isOptionEqualToValue={(option, value) =>
                  option.RECRUITMENT_GROUP === value.RECRUITMENT_GROUP
                }
                sx={{
                  width: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "300px",
                  },
                  paddingRight: {
                    xs: "0px",
                    sm: "0px",
                    md: "10px",
                    lg: "10px",
                  },
                  paddingBottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "0px",
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Localização" />
                )}
              />
              <PageviewIcon
                fontSize="large"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                  },
                  width: "80px",
                  height: "80px",
                  color: "#3ea938",
                }}
              />
            </Grid>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          className="bckretangle"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            alignItems: "center",
          }}
        >
        </Grid>
      </Grid>
      <Grid
        sx={{
          marginBottom: "-30px",
          paddingTop: { xs: "40px", sm: "40px", md: "100px", lg: "100px" },
        }}
      >
        <ListOffer
          inputValueJob={valueJob}
          inputValueGroup={inputValueGroup}
          numberOfItems={6}
        />
      </Grid>
 
      <Footer />
      <BtnWhatsapp />
    </div>
  );
}

