import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Message(props) {
  //SnackBar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setAlert(false);
  };
  //
  
  return (
    <Snackbar
      open={props.alert}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert severity="info" onClose={handleClose} sx={{ width: "100%", backgroundColor: "#48aa37" }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
