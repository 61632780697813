import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkIcon from "@mui/icons-material/Work";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { withStyles } from "@material-ui/core/styles";
import { useIsAuthenticated } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import { Link } from "react-router-dom";

function HeaderMenu(props) {
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const ListItem = withStyles({
    root: {
      "&": {
        color: "#231f20",
      },
      "&:hover": {
        backgroundColor: "#0000000a",
      },
      "&$selected": {
        color: "#48aa37",
        backgroundColor: "transparent",
      },
    },
    selected: {},
  })(MuiListItem);

  return (
    <div>
      <List>
        <ListItem
          component={Link}
          to="/"
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        <Link to="/dashboard/home" className="dashLink">
          <ListItem>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{
                fontWeight: "bold",
              }}
              disableTypography
            />
          </ListItem>
        </Link>

        <ListItem
          component={Link}
          to="/"
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText
            primary="Candidaturas"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        <ListItem
          component={Link}
          to="/"
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            primary="Pedir proposta"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        <ListItem
          component={Link}
          to="/"
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <ContactPageIcon />
          </ListItemIcon>
          <ListItemText
            primary="Contactos"
            sx={{ fontWeight: "bold" }}
            disableTypography
          />
        </ListItem>

        {!isAuthenticated() ? (
          <ListItem
            component={Link}
            to="/login"
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText
              primary="Login"
              sx={{ fontWeight: "bold" }}
              disableTypography
            />
          </ListItem>
        ) : (
          <ListItem
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{ fontWeight: "bold" }}
              disableTypography
              onClick={() => signOut()}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
}

export default HeaderMenu;