import React from "react";
import "./App.css";
import { AuthProvider } from "react-auth-kit";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Homepage from "./pages/Homepage";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Ativate from "./pages/Ativate";
import Offer from "./pages/Offer";
import NoMatch from "./components/NoMatch";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
  faWhatsappSquare,
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 999,
      md: 1001,
      lg: 1200,
      xl: 1536,
    },
  },
});

library.add(
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
  faWhatsappSquare,
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faWhatsapp,
  faEnvelope,
  faUser
);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AuthProvider
          authType={"cookie"}
          authName={"_auth"}
          cookieDomain={window.location.hostname}
          cookieSecure={window.location.protocol === "https:"}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/login" element={<Login />} />
              <Route
                index
                path={"/dashboard/*"}
                element={
                  <RequireAuth loginPath={"/login"}>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route path="/register" element={<Register />} />
              <Route path="/register/ativate" element={<Ativate />} />
              <Route path="/offer/:id" element={<Offer />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}