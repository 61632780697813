import React, { useRef, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function DraftMessage({ messages, updatemessage }) {
  const hasFetchedData = useRef(false);

  //Default values quando faz refresh da página
  useEffect(() => {
    if (!hasFetchedData.current) {
      // console.log(messages);
      updatemessage(messages);
      hasFetchedData.current = true;
    }
  }, [messages, updatemessage]);

  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Mensagens de Rascunho
        </Typography>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={messages}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "date",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

const columns = [
  { field: "id", hide: true },
  { field: "address", headerName: "Para", minWidth: 130, flex: 1 },
  { field: "subject", headerName: "Assunto", minWidth: 130, flex: 1 },
  { field: "date", headerName: "Data", minWidth: 130, flex: 1 },
  {
    field: "open",
    headerName: "Ver Mensagem",
    minWidth: 130,
    flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <Link to={`/dashboard/messages/edit/${params.id}`}>
          <Button
            variant="contained"
            className="btnPrimary"
            startIcon={<VisibilityIcon className="ml-15" />}
          ></Button>
        </Link>
      );
    },
  },
];
