import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Settings({ users, updateuser }) {
  const auth = useAuthUser();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      USERS: users,
      USER_COMMUNICATION_TYPE: users.USER_COMMUNICATION_TYPE,
      USER_NEWSLETTER: users.USER_NEWSLETTER,
      
    },
  });

  const USER_STATUS = getValues("USERS.USER_STATUS");


  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(users);
  }, [users]);

  //Marketing Checked
  const [checkedMarketing, setCheckedMarketing] = useState(false);

  const handleChangeMarketing = () => {
    setCheckedMarketing(!checkedMarketing);
  };
  //

  const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/user`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        updateuser(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "As configurações da conta foram guardadas com sucesso!";
  //

  //Delete User
  const handleClick = () => {
    var confirm = window.confirm("Tem a certeza que deseja remover a conta?");
    if (confirm) {
      axios
        .delete(
          `${process.env.REACT_APP_API}/destroy/${auth().user.NIF_UTILIZADOR}`,
          {
            headers: {
              Authorization: "Bearer " + auth().token,
            },
          }
        )
        .then((res) => {
          console.log("User deleted");
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Cancelled");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} p={1} align="left">
            <Typography variant="h6" p={1} className="title">
              Configurações da conta
            </Typography>
          </Grid>
          <Grid item xs={12} p={1}>
            <Typography variant="h6" className="title">
              Editar políticas de privacidade
            </Typography>
          </Grid>
          <Grid item xs={12} p={1}>
            <Typography variant="subtitle2" pb={2} align="left">
              Autorizo que os meus dados pessoais contidos neste formulário e/ou
              outros documentos submetidos por mim, sejam objeto de tratamento
              pelas empresas do Grupo SW para as seguintes finalidades:
            </Typography>
            <Typography
              variant="subtitle2"
              pb={2}
              align="left"
              className="titleBold"
            >
              Participação e envio de comunicações referentes a processos de recrutamento e seleção (escolha apenas uma das opções):
            </Typography>
          </Grid>
          <Grid item xs={12} p={1} align="left">
            <FormControl component="fieldset">
              <Controller
                aria-label="communication"
                name="USER_COMMUNICATION_TYPE"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="0"
                        control={<Radio color="success" />}
                        label="De oportunidades de empregos sugeridas pela SW ou através da minha candidatura no Portal de Emprego"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="success" />}
                        label="Unicamente através da minha candidatura no Portal de Emprego"
                        sx={{
                          paddingTop: { xs: "20px", sm: "20px", md: "20px" },
                        }}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
            <ErrorMessage
              errors={errors}
              name="USER_COMMUNICATION_TYPE"
              as="p"
              className="error"
              message="Escolha uma das opções de cima."
            />
          </Grid>
          <Grid item xs={12} p={1} align="left">
            <FormControlLabel
              control={
                <Controller
                  name="USER_NEWSLETTER"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      color="success"
                      onClick={handleChangeMarketing}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      label="Checkbox with label"
                    />
                  )}
                />
              }
              label="Ações de marketing para divulgação de notícias, eventos e promoção de serviços"
            />
          </Grid>
          <Grid item xs={12} p={1}>
            <Typography variant="subtitle2" pt={2} pb={2}>
              O consentimento prestado para o tratamento de dados pessoais pelo
              titular de dados pessoais, poderá ser revogado por si, a todo o
              momento. Para revogar o consentimento do tratamento dos seus dados
              pessoais para as finalidades acima enunciadas, ou para exercer os
              seus direitos de acesso, retificação, oposição, apagamento,
              limitação e portabilidade, por favor, consulte a nossa{" "}
              <a
                href="https://swork.pt/politica-de-privacidade/"
                target="_blank"
                rel="noreferrer"
                className="linkHome"
              >
                política de privacidade
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} p={1} pt={2} pb={2}>
            <Button
              type="submit"
              value="submit"
              variant="contained"
              className="btnPrimary"
            >
              Gravar
            </Button>
            <Message
              message={message}
              alert={alert}
              setAlert={(updated) => setAlert(updated)}
            />
          </Grid>
        </Grid>
      </form>
      {/* Edit User */}
      <Grid container>
        <Grid item xs={12} p={1} pt={2}>
          <Typography variant="h6" color="primary">
            Alterar password
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <a
            href="https://api.swork.pt/password/reset"
            target="_blank"
            rel="noreferrer"
            className="noTextDecoration"
          >
            <Button variant="contained" color="primary">
              Editar
            </Button>
          </a>
        </Grid>
      </Grid>
      {/* Delete User */}
      <Grid container>
        <Grid item xs={12} p={1} pt={2}>
          <Typography variant="h6" color="error">
            Eliminar conta
          </Typography>
        </Grid>
        <Grid item xs={12}  p={1}>
          <Typography variant="subtitle2" pb={2} align="left">
            Caso seja candidato a emprego e nunca tenha trabalhado connosco, ao
            clicar em eliminar conta o seu registo será eliminado
            automaticamente da nossa base de dados de forma permanente e
            irreversível. Caso seja nosso colaborador ou já tenha trabalhado
            anteriormente os seus dados serão armazenados dentro dos prazos
            estipulados pela legislação. Para mais informação deverá consultar a
            nossa{" "}
            <a
              href="https://swork.pt/politica-de-privacidade/"
              target="_blank"
              rel="noreferrer"
              className="linkHome"
            >
              política de privacidade
            </a>{" "}
            ou enviar um email para{" "}
            <a href="mailto:privacidade@swork.pt">privacidade@swork.pt</a>
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Button variant="contained" color="error" onClick={handleClick} disabled={users.USER_STATUS == 1 || users.USER_STATUS == 2  ? true : false }>
            Remover Conta
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}