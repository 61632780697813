import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewsletterForm from "./NewsletterForm";

function Footer() {

  return (
    <div>
      <Container maxWidth="false" className="contactContainer">
        <Grid
          container
          p={6}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          textAlign="center"
          sx={{
            paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
            paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
            marginTop: { xs: "50px", sm: "50px", md: "50px", lg: "72px" },
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography
              variant="h5"
              sx={{
                paddingBottom: { xs: "40px", sm: "40px", md: "0", lg: "0" },
              }}
            >
              Onde estamos
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{'font-size':'medium'}}>
               <b>Quarteira</b><br></br>
                +351 289 388 531<br></br>
                (Chamada para a rede fixa nacional)<br/>
                geral@swork.pt<br></br>
                <a style={{'color': '#ffffff', 'textDecoration': 'none'}} href="https://www.google.pt/maps/dir//SW+Success+Work,+Empresa+de+Trabalho+Tempor%C3%A1rio+-+Quarteira,+Galerias+Central,+Largo+das+Cortes+Reais+Loja+9,+Quarteira/@37.0692578,-8.1424668,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd1ab5a3f09aaca7:0x38d648e5ac9db8ce!2m2!1d-8.1074908!2d37.0692727!3e0" target="_blank" rel="noopener">Direções</a>

              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{'font-size':'medium'}}>
                <b>Albufeira</b><br></br>
                +351 289 245 505<br></br>
                (Chamada para a rede fixa nacional)<br/>
                albufeira@swork.pt<br></br>
                <a style={{'color': '#ffffff', 'textDecoration': 'none'}} href="https://www.google.com/maps/dir//SW+Trabalho+Tempor%C3%A1rio,+R.+de+Dunfermline+3+Frac%C3%A7%C3%A3o+C,+8200-391+Albufeira/@37.0900039,-8.2343074,18z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd1acd48f1429b73:0x1a877f1d368f621d!2m2!1d-8.2346463!2d37.0910014!3e0?hl=pt-PT" target="_blank" rel="noopener">Direções</a>

              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{'font-size':'medium'}}>
                <b>Portimão</b><br></br>
                +351 282 422 412<br></br>
                (Chamada para a rede fixa nacional)<br/>
                portimao@swork.pt<br></br>                
                <a style={{'color': '#ffffff', 'textDecoration': 'none'}} href="https://www.google.pt/maps/dir//Success+Work,+Tv.+do+P%C3%A9+da+Cruz+9,+8500-521+Portim%C3%A3o/@37.136791,-8.5424604,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd1b28ed721eae99:0x17d9301a9e59e51b!2m2!1d-8.5403145!2d37.1367795!3e0" target="_blank" rel="noopener">Direções</a>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{'font-size':'medium'}}>
                <b>Lisboa</b><br></br>
                +351 217 959 482<br></br>
                (Chamada para a rede fixa nacional)<br/>
                geral.lisboa@swork.pt<br></br>
                <a style={{'color': '#ffffff','textDecoration': 'none'}} href="https://www.google.com/maps?cid=9208360903083477171&authuser=1&_ga=2.57788909.650101779.1624285550-468917236.1617892025" target="_blank" rel="noopener">Direções</a>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        p={10}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
          textAlign: { xs: "center", sm: "center", md: "center", lg: "center" },
        }}
      >
        <Grid item xs={12} sm={5}
          sx={{
            paddingTop: { xs: "25px", sm: "25px", md: "25px", lg: "25px" }
          }}>
          <img
            src="/icons/mf2020.svg"
            alt="mf2020"
            className="iconLeftFooter"
          />
          <img
            src="/icons/mf2021.svg"
            alt="mf2021"
            className="iconLeftFooter"
          />
          <img
            src="/icons/mf2022.svg"
            alt="mf2022"
            className="iconLeftFooter"
          />
          <img
            src="/icons/premio2023.png"
            alt="mf2023"
            className="iconLeftFooter"
          />
        </Grid>
        <Grid item xs={12} sm={7} sx={{
          textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
        }}>
          <NewsletterForm />

          <Grid item xs={12}>
            <Grid container direction="row" alignItems="end">
              <Grid item xs={12} sm={12} md={5}>
                <Typography className="title" pt={10}>
                  O seu sucesso está aqui.<br />
                  Não o perca de vista!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} md={4} className="allIconsRight">
                <a
                  className="btnfooter"
                  href="https://www.facebook.com/successworkpt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-facebook-f"
                    className="iconRightFooter"
                  />
                </a>
                <a
                  className="btnfooter"
                  href="https://www.linkedin.com/company/successworkpt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-linkedin"
                    className="iconRightFooter"
                  />
                </a>
                <a
                  className="btnfooter"
                  href="https://www.instagram.com/successworkpt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-instagram"
                    className="iconRightFooter"
                  />
                </a>
                <a
                  className="btnfooter"
                  href="https://api.whatsapp.com/send/?phone=926234709&text=Ao+iniciar+esta+conversa+autoriza+que+a+Success+Work+processe+e+trate+os+seus+dados+pessoais%2C+de+acordo+com+a+sua+politica+de+privacidade+dispon%C3%ADvel+em%3A+https%3A%2F%2Fswork.pt%2Fpolitica-da-privacidade%2F&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="iconRightFooter"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
          textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
        }}
      >
        <Grid item xs={12} sm={5}>
          <Grid
            container
            p={3}
            className="bgGreen"
            sx={{
              paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "100px" },
              paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "100px" },
            }}
          >
            <Grid className="rights">
              © {new Date().getFullYear()} Success Work. Todos os direitos reservados
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container p={3} className="bgWhite">
            <Grid className="footerLinks">
              <a
                href="https://swork.pt/responsabilidade-social/"
                target="_blank"
                rel="noreferrer"
              >
                Responsabilidade Social
              </a>
              <a
                href="https://swork.pt/politica-de-qualidade/"
                target="_blank"
                rel="noreferrer"
              >
                Política de Qualidade
              </a>
              <a
                href="https://swork.pt/termos-e-condicoes/"
                target="_blank"
                rel="noreferrer"
              >
                Termos e Condições
              </a>
              <a
                href="https://swork.pt/politica-de-privacidade/"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
              <a
                href="https://swork.pt/politica-de-cookies/"
                target="_blank"
                rel="noreferrer"
              >
                Cookies
              </a>
              <a
                href="https://www.livroreclamacoes.pt/Inicio/"
                target="_blank"
                rel="noreferrer"
              >
                Livro de Reclamações
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>

  );
}

export default Footer;
