import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Slide from '@mui/material/Slide';

function BtnWhatsapp() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const handleWhatsapp = () => {
        window.open("https://api.whatsapp.com/send/?phone=926234709", "_blank");
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });
  return (
    <>
    <Button onClick={handleClickOpen}>
        <div className="areawhatsapp">
        <WhatsAppIcon className="btnwhatsapp"/>
        </div>
    </Button> 

    <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Pretende iniciar a conversa?"}</DialogTitle>
        <DialogContent>
        {"Ao iniciar esta conversa autoriza que a Success Work processe e trate os seus dados pessoais, de acordo com a sua politica de privacidade disponível em: https://swork.pt/politica-da-privacidade/"}
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => { handleWhatsapp() }}>Sim</Button>
          <Button onClick={handleClose}>Não</Button>
        </DialogActions>
      </Dialog>
    </>
    
  );
}

export default BtnWhatsapp;
