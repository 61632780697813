import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Message from "../components/Message";
import { useAuthUser } from 'react-auth-kit';
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function Idiomas({ languages, idiomas, updateidioma }) {
  const auth = useAuthUser();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(languages);
    // console.log(idiomas);
  }, [languages, idiomas]);

   const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/userAbilities`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        updateidioma(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "Os idiomas foram guardados com sucesso!";
  //

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Idiomas
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Controller
            control={control}
            name="abilities_array"
            defaultValue={idiomas}
            rules={{ required: "O idioma é obrigatório." }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                id="tags-outlined"
                options={languages}
                getOptionLabel={(option) => option.DESCRICAO}
                isOptionEqualToValue={(option, value) =>
                  option.DESCRICAO === value.DESCRICAO
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Idiomas"
                    placeholder="Escolher"
                    error={!!errors.abilities_array}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="abilities_array"
            as="p"
            className="error"
          />
        </Grid>

        <Grid item xs={12} p={1}>
          <Button
            type="submit"
            value="submit"
            variant="contained"
            className="btnPrimary"
          >
            Gravar
          </Button>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
<BtnWhatsapp />
    </div>
  );
}
