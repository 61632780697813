import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useForm, Controller } from "react-hook-form";
import Message from "../components/Message";
import { useAuthUser } from 'react-auth-kit';
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function AdditionalInformation({ users, updateuser }) {
  const auth = useAuthUser();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      USER_HAS_DRIVERS_LICENCE: users.USER_HAS_DRIVERS_LICENCE,
      USER_HAS_CAR: users.USER_HAS_CAR,
    },
  });

  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(user);
  }, [users]);

  const onSubmit = (data) => {
    axios
      .patch(`${process.env.REACT_APP_API}/user`, data, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        updateuser(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "As informações adicionais foram guardadas com sucesso!";
  //

  return (
    <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Informações adicionais
          </Typography>
        </Grid>
        <Grid item xs={12} p={1} align="left">
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Tem carta de condução válida
            </FormLabel>
            <Controller
              aria-label="cartaconducao"
              name="USER_HAS_DRIVERS_LICENCE"
              control={control}
              render={({ field }) => {
                return (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="success" />}
                      label="Não"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="success" />}
                      label="Sim"
                    />
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} p={1} align="left">
          <FormControl component="fieldset">
            <FormLabel component="legend">Tem viatura própria</FormLabel>
            <Controller
              aria-label="viaturapropria"
              name="USER_HAS_CAR"
              control={control}
              render={({ field }) => {
                return (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="0"
                      control={<Radio color="success" />}
                      label="Não"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="success" />}
                      label="Sim"
                    />
                  </RadioGroup>
                );
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} p={1}>
          <Button
            type="submit"
            value="submit"
            variant="contained"
            className="btnPrimary"
          >
            Gravar
          </Button>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
    <BtnWhatsapp />
    </div>
  );
}