import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthUser } from "react-auth-kit";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import BtnWhatsapp from "../components/BtnWhatsapp";


import axios from "axios";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function WorkSchedules({ workshifts }) {
  const auth = useAuthUser();

  const [events, setEvents] = useState(workshifts);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);

  const [schedulesMonths, setSchedulesMonth] = useState([]);
  const [schedulesYears, setSchedulesYears] = useState([]);

  const [status, setStatus] = useState(false);

  //Change Task State & Modal
  const [justification, setJustification] = useState("");


  //Default values quando faz refresh da página
  useEffect(() => {
    // console.log(events);
  }, [events]);

  let weekday = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];

  let month = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/workshiftsmonths`, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        if(res.data.length !== 0){
          setSchedulesMonth(res.data);
          setSelectedMonth(res.data[0].USER_WORK_SHIFT_MONTH_ID);
          setStatus(true);
        }else{
          setStatus(false);
        }
       
      })
      .catch((err) => {
        console.log(err);
      }
      );  
    }, []);

  useEffect(() => {
    //YEAR USER SCHEDULES
    axios
      .get(`${process.env.REACT_APP_API}/workshiftsyears`, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        if(res.data.length !== 0){
          setSchedulesYears(res.data);
          setSelectedYear(res.data[0].USER_WORK_SHIFT_YEAR);
          setStatus(true);
        }else{
          setStatus(false);
        }
       
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    //console.log(schedulesYears[0].USER_WORK_SHIFT_YEAR + " | " + schedulesMonths[0].USER_WORK_SHIFT_MONTH_ID)
    //GET LIST USER SCHEDULES 
    if (schedulesMonths.length > 0 || schedulesYears.length > 0) {
      //console.log("START LOAD DATA " + `${process.env.REACT_APP_API}/workshifts?MES=${selectedMonth}&ANO=${selectedYear}`);
      axios
        .get(
          `${process.env.REACT_APP_API}/workshifts?MES=${selectedMonth}&ANO=${selectedYear}`,
          {
            headers: {
              Authorization: "Bearer " + auth().token,
            },
          }
        )
        .then((res) => {
         // console.log(res.data);
          setEvents(res.data)
          setLoading(false);
        })
        .catch((err) => {
          if (err.status !== 200) {
            console.log(err);
          }
        });
    }

  }, [selectedMonth, selectedYear]);


  //if (loading === true) return "loading";

  //Filters
  const handleSearchMonth = (e) => {
    console.log(e.target.value);
    //setSearch(e.target.value);
    setSelectedMonth(e.target.value);
  };

  const handleSearchYear = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSearchJob = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchCompany = (e) => {
    setSearch(e.target.value);
  };

  //Get today date
  var today = new Date();
  today.setDate(today.getDate() - 1);

  const filtered = !search
    ? events.filter(
      (event) => new Date(event.USER_WORK_SHIFT_START_DATE) > today
    ) //Filter the day and beyond
    : events.filter(
      (event) =>
        month[new Date(event.USER_WORK_SHIFT_START_DATE).getMonth()].includes(
          search
        ) ||
        [new Date(event.USER_WORK_SHIFT_START_DATE).getFullYear()].includes(
          search
        ) ||
        event.USER_WORK_SHIFT_CATEGORY.includes(search) ||
        event.USER_WORK_SHIFT_LOCAL.includes(search)
    );

  const clearFilter = (e) => {
    setSearch("");
  };
  //


  function handleTask(index, e, event) {
    const value = [...filtered];
    value[index][e.currentTarget.name] = e.currentTarget.value;
    setEvents(value);
    setSelectedEvent([]);
    // console.log(events);

    fetch(`${process.env.REACT_APP_API}/updateworkshifts`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + auth().token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        USER_WORK_SHIFT_ID: event.USER_WORK_SHIFT_ID,
        USER_WORK_SHIFT_NUMBER: event.USER_WORK_SHIFT_NUMBER,
        USER_WORK_SHIFT_LINE_NUMBER: event.USER_WORK_SHIFT_LINE_NUMBER,
        USER_WORK_SHIFT_START_DATE: event.USER_WORK_SHIFT_START_DATE.split(" ")[0],
        USER_WORK_SHIFT_START_TIME: event.USER_WORK_SHIFT_START_TIME,
        USER_WORK_SHIFT_END_DATE: event.USER_WORK_SHIFT_END_DATE,
        USER_WORK_SHIFT_END_TIME: event.USER_WORK_SHIFT_END_TIME,
        USER_WORK_SHIFT_LOCAL_ID: event.USER_WORK_SHIFT_LOCAL_ID,
        USER_WORK_SHIFT_LOCAL: event.USER_WORK_SHIFT_LOCAL,
        USER_WORK_SHIFT_CATEGORY: event.USER_WORK_SHIFT_CATEGORY,

        //PATCH
        USER_WORK_SHIFT_STATE: e.currentTarget.value,
        USER_WORK_SHIFT_JUSTIFICATION: justification,
      }),
    })
      .then((response) => response.json())
      .then((json) => console.log(json));
  }

  const handleOpenModal = (event) => () => {
    setSelectedEvent((selectedEvent) =>
      selectedEvent.id === event.USER_WORK_SHIFT_ID ? null : event
    );
  };

  function handleJustification(index, e) {
    const value = [...filtered];
    value[index][e.currentTarget.name] = e.currentTarget.value;
    setEvents(value);
    // console.log(events);

    setJustification(e.currentTarget.value);
  }
  //

  //Hide Duplicates
  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const uniqueCompany = getUnique(events, "USER_WORK_SHIFT_LOCAL");
  const uniqueJob = getUnique(events, "USER_WORK_SHIFT_CATEGORY");
  //

  //Hide Years Duplicates
  var years = events.map((event) =>
    new Date(event.USER_WORK_SHIFT_START_DATE).getFullYear()
  );
  var newYearsArray = years.filter(function (elem, pos) {
    return years.indexOf(elem) === pos;
  });
  //

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Escalas de trabalho
          </Typography>
        </Grid>

        <Grid item xs={12} pb={2}>
        {
          status == true && 
          <Alert severity="info">
            A informação disponibilizada é relativa aos últimos 90 dias!
          </Alert>
        }
        </Grid>

        <Grid item xs={12} pb={2}>
          {
          status == false && 
            <Alert severity="warning">
              Neste momento não se encontra com escalas disponíveis.
          </Alert>
          }
        </Grid>
        
        <Grid container>
        <Grid item xs={12} sm={6} p={1} align="center">
          <Select
            disabled = {!status}
            style={{ "width": "100%"}}
            labelId="yearSelectLabel"
            id="yearSelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchYear}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Ano</em>
            </MenuItem>
            {schedulesYears.map((item, index) => (
              <MenuItem key={index} value={item.USER_WORK_SHIFT_YEAR} className="company">
                <span className="company">{item.USER_WORK_SHIFT_YEAR}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} p={1} align="center">
          <Select
            disabled = {!status}
            style={{ "width": "100%"}}
            labelId="taskSelectLabel"
            id="taskSelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchMonth}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Mês</em>
            </MenuItem>
            {schedulesMonths.map((item, index) => (
              <MenuItem key={index} value={item.USER_WORK_SHIFT_MONTH_ID} className="company">
                <span className="company">{item.USER_WORK_SHIFT_MONTH}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={12} p={1} align="center">
          <Select
            disabled = {!status}
            style={{ "width": "100%"}}
            labelId="jobSelectLabel"
            id="jobSelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchJob}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Profissão</em>
            </MenuItem>
            {uniqueJob.map((item, index) => (
              <MenuItem
                key={index}
                value={item.USER_WORK_SHIFT_CATEGORY}
                className="company"
              >
                <span className="company">
                  {item.USER_WORK_SHIFT_CATEGORY}
                </span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} p={1} align="center">
          <Select
            disabled = {!status}
            style={{ "width": "100%"}}
            labelId="companySelectLabel"
            id="companySelect"
            defaultValue=""
            displayEmpty
            variant="standard"
            onChange={handleSearchCompany}
          >
            <MenuItem onChange={clearFilter} value="">
              <em>Empresa</em>
            </MenuItem>
            {uniqueCompany.map((item, index) => (
              <MenuItem
                key={index}
                value={item.USER_WORK_SHIFT_LOCAL}
                className="company"
              >
                <span className="company">{item.USER_WORK_SHIFT_LOCAL}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        </Grid>
        {
            status == true
          &&
          <Grid item xs={12} p={1} pt={3} pb={5} align="left">
            <Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
              <Chip label="Trabalho aceite" color="success" />
              <Chip label="Por Aceitar" color="warning" />
              <Chip label="Recusado" color="error" />
            </Stack>
          </Grid>
        }
        

        <Grid item xs={12} p={1} align="left">
          {/* Utiliza o sort com o campo date dos events */}
          {filtered
            .sort(
              (a, b) =>
                new Date(b.USER_WORK_SHIFT_START_DATE) -
                new Date(a.USER_WORK_SHIFT_START_DATE)
            )
            .map((event, index) => (
              <Grid container spacing={1} marginTop={4} key={event.USER_WORK_SHIFT_ID}>
                <Grid item xs={12} md={2} p={1} align="left" className="colorSche"
                  sx={{
                    backgroundColor:
                      event.USER_WORK_SHIFT_STATE === "0"
                        ? "#ed6c02"
                        : "" || event.USER_WORK_SHIFT_STATE === "1"
                          ? "#48aa37 "
                          : "" || event.USER_WORK_SHIFT_STATE === "2" 
                            ? "#d32f2f"
                            : "",
                    minWidth: "80px",
                  }}
                >{/* Força o zero para dar consistência quando só tem um numero, o splice serve para mostrar apenas os ultimos 2 números */}
                  <p className="getDate">
                    {("0" + [new Date(event.USER_WORK_SHIFT_START_DATE).getDate()]).slice(-2)}
                  </p>
                  <p className="getMonth">
                    {
                      month[
                      new Date(event.USER_WORK_SHIFT_START_DATE).getMonth()
                      ]
                    }
                  </p>
                </Grid>
                <Grid item xs={12} md={8} p={1} align="left" className="greySche" style={{ "display": "block", "height": "100%" }}
                >
                  <p className="weekday">
                    {
                      weekday[new Date(event.USER_WORK_SHIFT_START_DATE).getDay()]
                    }
                  </p>
                  <p className="company">
                    {event.USER_WORK_SHIFT_START_TIME.split(".")[0]} às{" "}
                    {event.USER_WORK_SHIFT_END_TIME.split(".")[0]} -{" "}
                    {event.USER_WORK_SHIFT_LOCAL} |{" "}
                    {event.USER_WORK_SHIFT_CATEGORY}
                  </p>
                </Grid>
                <Grid item xs={12} md={2} align="center">
                  {DateCalculation(event.USER_WORK_SHIFT_START_DATE,event.USER_WORK_SHIFT_START_TIME,4) == true ? (
                    <div>
                      <IconButton
                        name="USER_WORK_SHIFT_STATE"
                        value="1"
                        onClick={(e) => handleTask(index, e, event)}
                        color="success"
                      >
                        <TaskAltIcon fontSize="large" />
                      </IconButton>
                      <IconButton onClick={handleOpenModal(event)} color="error">
                      <RemoveCircleOutlineIcon fontSize="large" />
                    </IconButton>
                    </div>
                  ) : (
                    <div>
                      <IconButton
                      disabled
                      name="USER_WORK_SHIFT_STATE"
                      value="1"
                      onClick={(e) => handleTask(index, e, event)}
                      color="success"
                    >
                      <TaskAltIcon fontSize="large" />
                    </IconButton>

                      <IconButton disabled onClick={handleOpenModal(event)} color="error">
                      <RemoveCircleOutlineIcon fontSize="large" />
                      </IconButton>
                      {ScheduleChip(event.USER_WORK_SHIFT_STATE)}
                    </div>
                    
                  )}

                </Grid>
                <Dialog
                  open={
                    selectedEvent.USER_WORK_SHIFT_ID === event.USER_WORK_SHIFT_ID
                  }
                >
                  <DialogTitle>Justificação:</DialogTitle>
                  <DialogContent>
                    <DialogContentText gutterBottom>
                      A tarefa vai ser rejeitada depois de preencher uma
                      justificação.
                    </DialogContentText>
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      autoFocus
                      margin="dense"
                      multiline
                      rows={5}
                      type="text"
                      name="USER_WORK_SHIFT_JUSTIFICATION"
                      defaultValue={event.USER_WORK_SHIFT_JUSTIFICATION}
                      onChange={(e) => handleJustification(index, e)}
                    />
                  </DialogContent>
                  <Grid item pr={1} sx={{ marginTop: "-10px" }}>
                    <DialogActions>
                      {event.USER_WORK_SHIFT_JUSTIFICATION === "" ? (
                        <Button
                          name="USER_WORK_SHIFT_STATE"
                          value="0"
                          onClick={(e) => handleTask(index, e, event)}
                        >
                          Gravar
                        </Button>
                      ) : (
                        <Button
                          name="USER_WORK_SHIFT_STATE"
                          value="2"
                          onClick={(e) => handleTask(index, e, event)}
                        >
                          Gravar
                        </Button>
                      )}
                    </DialogActions>
                  </Grid>
                </Dialog>
              </Grid>
            ))}
        </Grid>
      </Grid>
  <BtnWhatsapp />
    </ThemeProvider>
  );
}

function DateCalculation(datestart, timestart,marginhour){
    let datescheduletxt = datestart.split("-");
    let timescheduletxt = timestart.split(":");

    let year = parseInt(datescheduletxt[0]);
    let month = parseInt(datescheduletxt[1]) - 1;
    let day = parseInt(datescheduletxt[2]);
    let hours = parseInt(timescheduletxt[0]-marginhour);
    let minutes = parseInt(timescheduletxt[1]);
    let seconds = parseInt(timescheduletxt[2]);

    let datetimeschedule = new Date(year, month, day, hours, minutes, seconds);
    let datetimenow = new Date();

    
    if (datetimeschedule < datetimenow) {
      return false;
    } else if (datetimeschedule > datetimenow) {
      return true;
    } else {
      return false;
    }
}

function ScheduleChip(state){
  if(state == 0) return <Chip label="Escala não validada" color="error" />;
  if(state == 1) return <Chip label="Aceite" color="success" />;
  if(state == 2) return <Chip label="Rejeitada" color="error" />;

}