import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PersonalInfo from "../components/Personalnfo";
import ProfessionaExperience from "../components/ProfessionaExperience";
import LiterarySkills from "../components/LiterarySkills";
import DashboardMenu from "../components/DashboardMenu";
import Idiomas from "../components/Idiomas";
import AdditionalInformation from "../components/AdditionalInformation";
import ProfessionaPreferences from "../components/ProfessionalPreferences";
import ProfessionalFormation from "../components/ProfessionalFormation";
import NewMessage from "../components/NewMessage";
import ShowMessage from "../components/ShowMessage";
import SentMessage from "../components/SentMessage";
import EditMessage from "../components/EditMessage";
import ReceivedMessage from "../components/ReceivedMessage";
import SendMessage from "../components/SendMessage";
import DraftMessage from "../components/DraftMessage";
import WorkSchedules from "../components/WorkSchedules";
import ApplicationsH from "../components/ApplicationsH";
import Contracts from "../components/Contracts";
import Receipts from "../components/Receipts";
import TimeSheets from "../components/TimeSheets";
import MedicineWork from "../components/MedicineWork";
import IRS from "../components/IRS";
import Settings from "../components/Settings";
import Home from "../components/Home";
import AttachFiles from "../components/AttachFiles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Fade from "@mui/material/Fade";
import Message from "../components/Message";
import ApiAlert from "../components/ApiAlert";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthUser } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import { Routes, Route, Link } from "react-router-dom";

import axios from "axios";

const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Button = styled("button", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "fixed",
  left: "-30px",
  top: "425px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: "fixed",
    left: "226px",
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Dashboard(props) {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  //START API FETCH
  //
  //
  //
  const [users, setUsers] = useState(null);
  const [countries, setCountries] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [qualifications, setQualifications] = useState(null);
  const [literaries, setLiteraries] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [idiomas, setIdiomas] = useState(null);
  const [categories, setCategories] = useState(null);
  const [areas, setAreas] = useState(null);
  const [hours, setHours] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [workshifts, setWorkshifts] = useState(null);
  const [recruitments, setRecruitments] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [payrolls, setPayrolls] = useState(null);
  const [timesheets, setTimesheets] = useState(null);
  const [irs, setIrs] = useState(null);
  const [medicines, setMedicines] = useState(null);
  const [messageReceived, setMessageReceived] = useState(null);
  const [messageSent, setMessageSent] = useState(null);
  const [messageDraft, setMessageDraft] = useState(null);

  //Update data when change link tabs
  const [updateuser, setUpdateUser] = useState();
  const [updatejob, setUpdateJob] = useState();
  const [updatequalifications, setUpdateQualifications] = useState();
  const [updatecategories, setUpdateCategories] = useState();
  const [updatehours, setUpdateHours] = useState();
  const [updateareas, setUpdateAreas] = useState();
  const [updateidioma, setUpdateIdioma] = useState();
  const [updatedocuments, setUpdateDocuments] = useState();
  const [updateattachments, setUpdateAttachments] = useState();
  const [updatemessage, setUpdateMessage] = useState();
  //

  //Error API
  const [apiErrorUser, setApiErrorUser] = useState(false);
  const [apiErrorCountries, setApiErrorCountries] = useState(false);
  const [apiErrorDistricts, setApiErrorDistricts] = useState(false);
  const [apiErrorJobs, setApiErrorJobs] = useState(false);
  const [apiErrorQualifications, setApiErrorQualifications] = useState(false);
  const [apiErrorLiteraries, setApiErrorLiteraries] = useState(false);
  const [apiErrorLanguages, setApiErrorLanguages] = useState(false);
  const [apiErrorIdiomas, setApiErrorIdiomas] = useState(false);
  const [apiErrorCategories, setApiErrorCategories] = useState(false);
  const [apiErrorAreas, setApiErrorAreas] = useState(false);
  const [apiErrorHours, setApiErrorHours] = useState(false);
  const [apiErrorDocuments, setApiErrorDocuments] = useState(false);
  const [apiErrorAttachments, setApiErrorAttachments] = useState(false);
  const [apiErrorWorkshifts, setApiErrorWorkshifts] = useState(false);
  const [apiErrorRecruitments, setApiErrorRecruitments] = useState(false);
  const [apiErrorContracts, setApiErrorContracts] = useState(false);
  const [apiErrorPayrolls, setApiErrorPayrolls] = useState(false);
  const [apiErrorTimesheets, setApiErrorTimesheets] = useState(false);
  const [apiErrorMedicines, setApiErrorMedicines] = useState(false);
  const [apiErrorIrs, setApiErrorIrs] = useState(false);
  const [apiErrorMReceived, setApiErrorMReceived] = useState(false);
  const [apiErrorMSent, setApiErrorMSent] = useState(false);
  const [apiErrorMDraft, setApiErrorMDraft] = useState(false);
  //
//Get last six months & present day
var lastSixMonths = new Date();
lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);

const convertedLastSixMonths = `${String(lastSixMonths.getDate()).padStart(
  2,
  "0"
)}/${String(lastSixMonths.getMonth() + 1).padStart(
  2,
  "0"
)}/${lastSixMonths.getFullYear()}`;

var presentDay = new Date();

const convertedPresentDay = `${String(presentDay.getDate()).padStart(
  2,
  "0"
)}/${String(presentDay.getMonth() + 1).padStart(
  2,
  "0"
)}/${presentDay.getFullYear()}`;
  //Get User
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/user`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setUsers(res.data);
        setLoading(false);
        setApiErrorUser(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorUser(true);
        }
      });

    return () => abortCont.abort();
  }, [updateuser]);
  //

  //Get Countries
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/countries`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setCountries(res.data);
        setLoading(false);
        setApiErrorCountries(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorCountries(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Districts
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/districts`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setDistricts(res.data);
        setLoading(false);
        setApiErrorDistricts(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorDistricts(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Jobs
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/job_experience`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setJobs(res.data);
        setLoading(false);
        setApiErrorJobs(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorJobs(true);
        }
      });

    return () => abortCont.abort();
  }, [updatejob]);
  //

  //Get Qualifications
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/qualifications`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setQualifications(res.data);
        setLoading(false);
        setApiErrorQualifications(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorQualifications(true);
        }
      });

    return () => abortCont.abort();
  }, [updatequalifications]);
  //

  //Get Literaries
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/skills`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setLiteraries(res.data);
        setLoading(false);
        setApiErrorLiteraries(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorLiteraries(true);
        }
      });

    return () => abortCont.abort();
  }, [updateuser]);
  //

  //Get Languages
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/abilities`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setLanguages(res.data);
        setLoading(false);
        setApiErrorLanguages(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorLanguages(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Idiomas
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/userAbilities`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setIdiomas(res.data);
        setLoading(false);
        setApiErrorIdiomas(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorIdiomas(true);
        }
      });

    return () => abortCont.abort();
  }, [updateidioma]);
  //

  //Get Jobs Categories
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/jobcategories`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setCategories(res.data);
        setLoading(false);
        setApiErrorCategories(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorCategories(true);
        }
      });

    return () => abortCont.abort();
  }, [updatecategories]);
  //

  //Get Areas
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/workingAreas`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setAreas(res.data);
        setLoading(false);
        setApiErrorAreas(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorAreas(true);
        }
      });

    return () => abortCont.abort();
  }, [updateareas]);
  //

  //Get Hours
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/workingHours`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        setHours(res.data);
        setLoading(false);
        setApiErrorHours(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorHours(true);
        }
      });

    return () => abortCont.abort();
  }, [updatehours]);
  //

  //Get Documents
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/documentTypes`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setDocuments(res.data);
        setLoading(false);
        setApiErrorDocuments(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorDocuments(true);
        }
      });

    return () => abortCont.abort();
  }, [updatedocuments]);
  //

  //Get Attachments
  useEffect(() => {
    const abortCont = new AbortController();
    axios
      .get(`${process.env.REACT_APP_API}/userlistattachments`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setAttachments(res.data);
        setLoading(false);
        setApiErrorAttachments(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorAttachments(true);
        }
      });

    return () => abortCont.abort();
  }, [updateattachments]);
  //

  //Get Workshifts
  useEffect(() => {
    const abortCont = new AbortController();
//TODO
    axios
      .get(
        `${process.env.REACT_APP_API}/workshifts?MES=8&ANO=2022`,
        {
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setWorkshifts(res.data);
        setLoading(false);
        setApiErrorWorkshifts(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorWorkshifts(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //


  //Get Recruitments
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(
        `${process.env.REACT_APP_API}/recruitments?DATE_FROM=${convertedLastSixMonths}&DATE_TO=${convertedPresentDay}`,
        {
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        setRecruitments(res.data);
        setLoading(false);
        setApiErrorRecruitments(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorRecruitments(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Contracts
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/contracts`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setContracts(res.data);
        setLoading(false);
        setApiErrorContracts(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorContracts(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  

  //Get Payrolls
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(
        `${process.env.REACT_APP_API}/payroll?DATE_FROM=${convertedLastSixMonths}&DATE_TO=${convertedPresentDay}`,
        {
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setPayrolls(res.data);
        setLoading(false);
        setApiErrorPayrolls(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorPayrolls(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //
  //Get Timesheets
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(
        `${process.env.REACT_APP_API}/timesheet?DATE_FROM=${convertedLastSixMonths}&DATE_TO=${convertedPresentDay}`,
        {
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        }
      )
      .then((res) => {
        setTimesheets(res.data);
        setLoading(false);
        setApiErrorTimesheets(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorTimesheets(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Medicines
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/medicine`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setMedicines(res.data);
        setLoading(false);
        setApiErrorMedicines(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorMedicines(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Irs
  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/irs`, {
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setIrs(res.data);
        setLoading(false);
        setApiErrorIrs(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorIrs(true);
        }
      });

    return () => abortCont.abort();
  }, []);
  //

  //Get Messages Received
  useEffect(() => {
    const abortCont = new AbortController();
    axios
      .get(`${process.env.REACT_APP_API}/received_messages`,{
        signal: abortCont.signal,
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        setMessageReceived(res.data);
        console.log("mensagens recebidas");
        console.log(res.data)
        setLoading(false);
        setApiErrorMReceived(false);
      })
      .catch((err) => {
        // console.log(err);
        if (err.status !== 200) {
          setApiErrorMReceived(true);
        }
      });

    return () => abortCont.abort();
  }, [updatemessage]);
 
    //Get Messages Sent
    useEffect(() => {
      const abortCont = new AbortController();
      axios
        .get(`${process.env.REACT_APP_API}/sent_messages`,{
          signal: abortCont.signal,
          headers: {
            Authorization: "Bearer " + auth().token,
          },
        })
        .then((res) => {
          setMessageSent(res.data);
          console.log("mensagens enviadas");
          console.log(res.data)
          setLoading(false);
          setApiErrorMReceived(false);
        })
        .catch((err) => {
          // console.log(err);
          if (err.status !== 200) {
            setApiErrorMReceived(true);
          }
        });
  
      return () => abortCont.abort();
    }, [updatemessage]);

  const handleDrawerDesktopToggle = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const matches = useMediaQuery("(min-width:1024px)");

  useEffect(() => {
    if (matches === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [matches]);

  // Logout Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openLogout = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //

  //Alert Message
  const [popup, setPopup] = useState(false);
  const [alert, setAlert] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>  
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="leftToolbar">
            <Link to="/">
              <img src="/logo.svg" className="logo" alt="logo" />
            </Link>
          </div>
          <div className="rightToolbar">
            <Typography noWrap component="div" className="username">
              {auth().user.USER_NAME}
            </Typography>
            <FontAwesomeIcon
              icon="fa-regular fa-user"
              className="avatar"
              onClick={handleClick}
            />
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openLogout}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => signOut()}>
                <LogoutIcon />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              paddingTop: "60px",
            },
          }}
        >
          <DashboardMenu />
        </Drawer>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              flexShrink: 0,
              border: "none",
            },
          }}
        >
          <DashboardMenu />
        </Drawer>
      </Box>
      <DrawerHeader
        sx={{
          height: "100%",
        }}
      >
        <Button
          className="btnMenu"
          open={open}
          onClick={handleDrawerDesktopToggle}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          Menu
        </Button>
      </DrawerHeader>
      <Box
        className="mainMargin"
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingLeft: { xs: "0px", sm: "100px", md: "100px", lg: "100px" },
        }}
      >
        <Routes>
          <Route
            path="/home"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "0px" },
                }}
              >
                <Home open={open} />
              </Main>
            }
          />
          <Route
            path="/user/infopersonal"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {users && countries && districts && (
                  <PersonalInfo
                    users={users}
                    countries={countries}
                    districts={districts}
                    updateuser={(updated) => setUpdateUser(updated)}
                  />
                )}
                {(apiErrorUser || apiErrorCountries || apiErrorDistricts) && (
                  <ApiAlert />
                  
                )}
              </Main>
            }
          />
          <Route
            path="/user/experience"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {jobs && (
                  <ProfessionaExperience
                    jobs={jobs}
                    updatejob={(updated) => setUpdateJob(updated)}
                  />
                )}
                {apiErrorJobs && <ApiAlert />}
              </Main>
            }
          />
            <Route
            path="/user/formation"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {qualifications && (
                  <ProfessionalFormation
                    qualifications={qualifications}
                    updatequalifications={(updated) => setUpdateQualifications(updated)}
                  />
                )}
                {apiErrorJobs && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/user/literaryskills"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {users && literaries && (
                  <LiterarySkills
                    users={users}
                    literaries={literaries}
                    updateuser={(updated) => setUpdateUser(updated)}
                  />
                )}
                {(apiErrorUser || apiErrorLiteraries) && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/user/idiomas"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {languages && idiomas && (
                  <Idiomas
                    languages={languages}
                    idiomas={idiomas}
                    updateuser={(updated) => setUpdateUser(updated)}
                    updateidioma={(updated) => setUpdateIdioma(updated)}
                  />
                )}
                {(apiErrorLanguages || apiErrorIdiomas) && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/user/addinfo"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {users && (
                  <AdditionalInformation
                    users={users}
                    updateuser={(updated) => setUpdateUser(updated)}
                  />
                )}
                {apiErrorUser && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/user/prefprof"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {categories && areas && hours && (
                  <ProfessionaPreferences
                    categories={categories}
                    areas={areas}
                    hours={hours}
                    updatecategories={(updated) => setUpdateCategories(updated)}
                    updateareas={(updated) => setUpdateAreas(updated)}
                    updatehours={(updated) => setUpdateHours(updated)}
                  />
                )}
                {(apiErrorCategories || apiErrorAreas || apiErrorHours) && (
                  <ApiAlert />
                )}
              </Main>
            }
          />
          
          <Route
            path="/user/attachfiles"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {documents && attachments && (
                  <AttachFiles
                    documents={documents}
                    attachments={attachments}
                    updatedocuments={(updated) => setUpdateDocuments(updated)}
                    updateattachments={(updated) =>
                      setUpdateAttachments(updated)
                    }
                  />
                )}
                {(apiErrorDocuments || apiErrorAttachments) && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/messages/new"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                <NewMessage
                  popup={(updated) => setPopup(updated)}
                  alert={(updated) => setAlert(updated)}
                />
              </Main>
            }
          />
          <Route
            path="/messages/show/:id"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                <ShowMessage />
              </Main>
            }
          />
          <Route
            path="/messages/sent/:id"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                <SentMessage />
              </Main>
            }
          />
          <Route
            path="/messages/edit/:id"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                <EditMessage
                  popup={(updated) => setPopup(updated)}
                  alert={(updated) => setAlert(updated)}
                />
              </Main>
            }
          />
          <Route
            path="/messages/received"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                  <ReceivedMessage
                    messages={messageReceived}
                    updatemessage={(updated) => setUpdateMessage(updated)}
                  />
                {apiErrorMReceived && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/messages/send"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                  <SendMessage
                    messages={messageSent}
                    updatemessage={(updated) => setUpdateMessage(updated)}
                  /> 
                {apiErrorMSent && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/messages/draft"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {messageDraft && (
                  <DraftMessage
                    messages={messageDraft}
                    updatemessage={(updated) => setUpdateMessage(updated)}
                  />
                )}
                {apiErrorMDraft && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/schedules"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {workshifts && <WorkSchedules workshifts={workshifts} />}
                {apiErrorWorkshifts && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/applications"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {recruitments && <ApplicationsH recruitments={recruitments} />}
                {apiErrorRecruitments && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/contracts"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {contracts && <Contracts contracts={contracts} />}
                {apiErrorContracts && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/receipts"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {payrolls && <Receipts payrolls={payrolls} />}
                {apiErrorPayrolls && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/timesheets"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {timesheets && <TimeSheets timesheets={timesheets} />}
                {apiErrorTimesheets && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/medicinework"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {medicines && <MedicineWork medicines={medicines} />}
                {apiErrorMedicines && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/view/irs"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {irs && <IRS irs={irs} />}
                {apiErrorIrs && <ApiAlert />}
              </Main>
            }
          />
          <Route
            path="/settings"
            element={
              <Main
                open={open}
                sx={{
                  paddingTop: { xs: "75px", sm: "110px" },
                }}
              >
                {loading && (
                  <Box className="loading">
                    <CircularProgress />
                  </Box>
                )}
                {users && (
                  <Settings
                    users={users}
                    updateuser={(updated) => setUpdateUser(updated)}
                  />
                )}
                {apiErrorUser && <ApiAlert />}
              </Main>
            }
          />
        </Routes>
      </Box>
      <Message
        message={popup}
        alert={alert}
        setAlert={(updated) => setAlert(updated)}
      />
    </Box>
  );
}