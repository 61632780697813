import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function IRS({ irs }) {
  return (
    <Grid container>
      <Grid item xs={12} p={1} align="left">
        <Typography variant="h6" p={1} className="title">
          Declarações de IRS
        </Typography>
      </Grid>
      <Grid p={1} sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={irs}
          getRowId={(row) => row.USER_ATTACHMENT_ID}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "USER_ATTACHMENT_NAME",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Grid>
  <BtnWhatsapp />
    </Grid>
  );
}

const columns = [
  { field: "USER_ATTACHMENT_ID", hide: true },
  { field: "USER_ATTACHMENT_NAME", headerName: "Nome", minWidth: 130, flex: 1 },
  {
    field: "USER_ATTACHMENT_LINK",
    headerName: "PDF",
    headerAlign: "center",
    align: "center",
    minWidth: 50,
    flex: 0,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div>
          <a href={"//" + `${params.value}`} target="_blank" download>
            <Button
              variant="contained"
              className="btnPrimary"
              startIcon={<FileDownloadIcon className="ml-15" />}
            ></Button>
          </a>
          
        </div>
      );
    },
  },
];
