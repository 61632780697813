import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Message from "../components/Message";
import IconButton from "@mui/material/IconButton";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Divider from "@mui/material/Divider";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import BtnWhatsapp from "../components/BtnWhatsapp";


export default function AttachFiles({
  documents,
  attachments,
  updatedocuments,
  updateattachments,
}) {

console.log("attachments");
console.log(attachments);

  const auth = useAuthUser();

  const { control, reset, watch } = useForm({
    mode: "onBlur",
  });

  //Array de fields
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents", // unique name for your Field Array
  });

  const filteredTypes = attachments.filter((item) => {
    return (
      item.USER_ATTACHMENT_CLASS_ID !== 500 &&
      item.USER_ATTACHMENT_CLASS_ID !== 201
    );
  });

  //Disabled Anexar Mais se tiver mais que 1 append form
  const filteredTypesLength = filteredTypes.length;
  const fieldsLength = fields.length;
  //

  //Default values
  useEffect(() => {
    reset({
      documents: filteredTypes,
    });
    // console.log(documents);
    // console.log(attachments);
  }, [reset, documents, attachments]);

  //FormData
  let formData = new FormData();

  //Procura todos os items com id 500
  const filteredCv = attachments.filter((item) => {
    return item.USER_ATTACHMENT_CLASS_ID === 500;
  });

  //Mostra apenas o último item com id 500
  const cvSliced = filteredCv.slice(-1);

  const filteredCc = attachments.filter((item) => {
    return item.USER_ATTACHMENT_CLASS_ID === 201;
  });

  const ccSliced = filteredCc.slice(-1);

  //Get Index Value of Select
  const [indexValue, setIndexValue] = useState();

  const handleIndex = (e) => {
    setIndexValue(e.target.value);
  };
  //

  //Submit
  const handleAttach = (e, indexValue) => {
    formData.append("USER_ATTACHMENT_CLASS_ID", indexValue);
    formData.append("USER_ATTACHMENT", e.target.files[0]);

    axios
      .post(`${process.env.REACT_APP_API}/usernewattachment`, formData, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        console.log(res.data);
        updatedocuments(res.data);
        updateattachments(res.data);
        setAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //

  //Delete File
  const handleDelete = (event, index) => {
    var confirm = window.confirm(
      "Tem a certeza que deseja remover o ficheiro?"
    );
    if (confirm) {
      const watchFileName = watch(`documents.${index}.USER_ATTACHMENT_NAME`);
      // console.log("USER_ATTACHMENT_NAME: ", watchFileName);

      axios
        .delete(
          `${process.env.REACT_APP_API}/userattachment?USER_ATTACHMENT_NAME=${watchFileName}`,
          {
            headers: {
              Authorization: "Bearer " + auth().token,
            },
          }
        )
        .then((res) => {
          console.log("Attach deleted");
          updatedocuments(res.data);
          updateattachments(res.data);
          setAlert(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Cancelled");
    }
  };
  //

  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const message = "Os ficheiros foram atualizados com sucesso!";
  //

  return (
    <div>
    <form>
      <Grid container>
        <Grid item xs={12} p={1} align="left">
          <Typography variant="h6" p={1} className="title">
            Anexar Ficheiros
          </Typography>
        </Grid>
        <Grid item xs={12} pl={1}>
          {cvSliced.length === 0 ? (
            <Typography align="left">
              <WarningIcon /> Verificamos que ainda não adicionou um ficheiro
              dos ficheiros obrigatórios!
            </Typography>
          ) : cvSliced.length === 0 ? (
            <Typography align="left">
              <WarningIcon /> Verificamos que ainda não adicionou um ficheiro
              dos ficheiros obrigatórios!
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className="cvcc"
          sx={{
            display: { xs: "block", sm: "flex" },
            paddingTop: { xs: "10px", sm: "60px" },
            paddingBottom: { xs: "30px", sm: "60px" },
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} display="block">
            <Grid item xs={12} p={1} pt={3} alignItems="center">
              <Controller
                name="USER_ATTACHMENT"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    accept=".pdf,.docx,.doc,image/jpeg"
                    type="file"
                    id="raised-button-file-cv"
                    style={{ display: "none" }}
                    onChange={(e) => handleAttach(e, 500)}
                  />
                )}
              />
              <label htmlFor="raised-button-file-cv">
                <Button
                  p={1}
                  variant="contained"
                  component="span"
                  color="primary"
                  sx={{ minWidth: "110px" }}
                >
                  ANEXAR CV
                </Button>
              </label>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                p={1}
                sx={{ paddingTop: { xs: "15px" } }}
              >
                {cvSliced.length !== 0
                  ? cvSliced.map((item, index) => {
                      return (
                        <div key={index}>
                          <Chip
                            label={item.USER_ATTACHMENT_NAME}
                            icon={<DoneIcon />}
                            color="primary"
                            variant="outlined"
                          />
                        </div>
                      );
                    })
                  : ""}
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {cvSliced.map((item, index) => {
                  return (
                    <div key={index}>
                      <a href={"https://" + item.USER_ATTACHMENT_LINK} target="_blank" rel="noopener" download>
                        <IconButton
                          color="primary"
                          aria-label=""
                        >
                          <DownloadForOfflineOutlinedIcon fontSize="large" />
                        </IconButton>
                        </a>
                    </div>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem></Divider>
          <Grid item xs={12} display="block">
            <Grid
              item
              xs={12}
              p={1}
              pt={3}
              alignItems="center"
              justifyContent="center"
            >
              <Controller
                name="USER_ATTACHMENT"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    accept=".pdf,.docx,.doc,image/jpeg"
                    type="file"
                    id="raised-button-file-cc"
                    style={{ display: "none" }}
                    onChange={(e) => handleAttach(e, 201)}
                  />
                )}
              />
              <label htmlFor="raised-button-file-cc">
                <Button
                  p={1}
                  variant="contained"
                  component="span"
                  color="primary"
                  sx={{ minWidth: "110px" }}
                >
                  ANEXAR CC
                </Button>
              </label>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                p={1}
                sx={{ paddingTop: { xs: "15px" } }}
              >
                {ccSliced.length !== 0
                  ? ccSliced.map((item, index) => {
                      return (
                        <div key={index}>
                          <Chip
                            label={item.USER_ATTACHMENT_NAME}
                            icon={<DoneIcon />}
                            color="primary"
                            variant="outlined"
                          />
                        </div>
                      );
                    })
                  : ""}
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {ccSliced.map((item, index) => {
                  return (
                    <div key={index}>
                       <a href={"https://" + item.USER_ATTACHMENT_LINK}
                        target="_blank" rel="noopener" download
                      >
                        <IconButton
                          color="primary"
                          aria-label="add to shopping cart"
                        >
                          <DownloadForOfflineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </a>
                    </div>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Divider className="dividerAttach">
          <Chip label="Outros Documentos" />
        </Divider>
        {fields.map((field, index) => {
          return (
            <Grid
              container
              key={field.id}
              className="fieldsW"
              sx={{ width: { xs: "100%", sm: "60%" } }}
            >
              <Grid item xs={12} p={1}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de documento
                  </InputLabel>
                  {!watch(`documents.${index}.USER_ATTACHMENT_ID`) ? (
                    <Controller
                      control={control}
                      name={`documents.${index}.USER_ATTACHMENT_CLASS_ID`}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Tipo de documento"
                          defaultValue=""
                          onChange={(e) =>
                            handleIndex(
                              e,
                              watch(
                                `documents.${index}.USER_ATTACHMENT_CLASS_ID`
                              )
                            )
                          }
                        >
                          {documents &&
                            documents
                              .filter(
                                (item) =>
                                  item.CODIGO_CLASSIFICACAO !== 201 &&
                                  item.CODIGO_CLASSIFICACAO !== 500
                              )
                              .map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item.CODIGO_CLASSIFICACAO}
                                >
                                  {item.DESCRICAO}
                                </MenuItem>
                              ))}
                        </Select>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`documents.${index}.USER_ATTACHMENT_CLASS_ID`}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Tipo de documento"
                          defaultValue=""
                          inputProps={{ readOnly: true }}
                          onChange={(e) =>
                            handleIndex(
                              e,
                              watch(
                                `documents.${index}.USER_ATTACHMENT_CLASS_ID`
                              )
                            )
                          }
                        >
                          {documents &&
                            documents
                              .filter(
                                (item) =>
                                  item.CODIGO_CLASSIFICACAO !== 201 &&
                                  item.CODIGO_CLASSIFICACAO !== 500
                              )
                              .map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item.CODIGO_CLASSIFICACAO}
                                >
                                  {item.DESCRICAO}
                                </MenuItem>
                              ))}
                        </Select>
                      )}
                    />
                  )}
                  {!watch(`documents.${index}.USER_ATTACHMENT_ID`) && (
                    <FormHelperText
                      id="component-helper-text"
                      sx={{ marginLeft: "3px;" }}
                    >
                      Selecione um tipo de documento
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} p={1}>
                <Controller
                  name={`documents.${index}.USER_ATTACHMENT`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      accept=".pdf,.docx,.doc,image/jpeg"
                      type="file"
                      id="raised-button-file-array"
                      style={{ display: "none" }}
                      onChange={(e) => handleAttach(e, indexValue)}
                    />
                  )}
                />
                <Grid item xs={12}>
                  <label htmlFor="raised-button-file-array">
                    {!watch(`documents.${index}.USER_ATTACHMENT_ID`) ? (
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        className="fl"
                        sx={{ minWidth: { md: "110px" } }}
                        disabled={indexValue === undefined}
                      >
                        Anexar documento
                      </Button>
                    ) : (
                      ""
                    )}
                  </label>
                  <Stack direction="row" spacing={1} className="fl">
                    {watch(`documents.${index}.USER_ATTACHMENT_ID`) ? (
                      <Chip
                        label={watch(`documents.${index}.USER_ATTACHMENT_NAME`)}
                        icon={<DoneIcon />}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    className="fl"
                    sx={{ marginTop: { xs: "-9.5px" } }}
                  >
                    {watch(`documents.${index}.USER_ATTACHMENT_ID`) ? (
                      <a href={"https://" +  watch(`documents.${index}.USER_ATTACHMENT_LINK`)}
                        download
                        target="_blank" rel="noopener"
                      >
                        <IconButton
                          color="primary"
                          aria-label="add to shopping cart"
                        >
                          <DownloadForOfflineOutlinedIcon fontSize="large" />
                        </IconButton>
                      </a>
                    ) : (
                      ""
                    )}
                  </Stack>
                  {watch(`documents.${index}.USER_ATTACHMENT_CAN_DELETE`) ===
                  1 ? (
                    <RemoveCircleOutlineOutlinedIcon
                      variant="contained"
                      onClick={(event) => {
                        handleDelete(event, index);
                        // remove(index);
                      }}
                      color="error"
                      className="fr"
                      fontSize="large"
                      sx={{
                        marginTop: { xs: "-0.5px" },
                        cursor: "pointer",
                      }}
                    ></RemoveCircleOutlineOutlinedIcon>
                  ) : (
                    <RemoveCircleOutlineOutlinedIcon
                      variant="contained"
                      onClick={() => {
                        remove(index);
                      }}
                      color="error"
                      className="fr"
                      fontSize="large"
                      sx={{
                        marginTop: { xs: "-0.5px" },
                        cursor: "pointer",
                      }}
                    ></RemoveCircleOutlineOutlinedIcon>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Divider
          className="dividerMore"
          sx={{ width: { xs: "100%", sm: "60%" } }}
        ></Divider>
        <Grid
          container
          className="fieldsW"
          sx={{ width: { xs: "100%", sm: "60%" } }}
        >
          <Grid item xs={12} p={1} pt={3}>
            <Button
              p={1}
              variant="contained"
              component="span"
              className="fr"
              color="warning"
              sx={{ minWidth: { xs: "100%", sm: "100px" } }}
              onClick={() => append({})}
              disabled={fieldsLength > filteredTypesLength}
            >
              Anexar mais documentos
            </Button>
          </Grid>
          <Grid item xs={12} p={1}>
            <Typography variant="subtitle2" className="fr">
              O ficheiro deve ser inferior a 5MB e apenas serão aceites
              ficheiros com as extensões pdf, docx, doc ou jpg/png.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} p={1}>
          <Message
            message={message}
            alert={alert}
            setAlert={(updated) => setAlert(updated)}
          />
        </Grid>
      </Grid>
    </form>
<BtnWhatsapp />
    </div>
  );
}