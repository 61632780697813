import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HeaderMenu from "../components/HeaderMenu";
import { useIsAuthenticated } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const drawerWidth = 260;

export default function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="leftToolbarLogin">
            <Link to="/">
              <img src="/logo.svg" className="logo" alt="logo" />
            </Link>
          </div>
          <Box
            className="rightToolbar"
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            {isAuthenticated() && (
            <Typography
              variant="subtitle1"
              noWrap
              component="div"
              className="titleHeader"
              pr="30px"
            >
              <Link
                to="/dashboard/home"
                style={{ fontSize:"16px",textDecoration: "none", color: "#231F20" }}
              >
                Dashboard
              </Link>
            </Typography>
            )}
            <Typography
              variant="subtitle1"
              noWrap
              component="div"
              className="titleHeader"
              pr="30px"
            >
              <a href="https://swork.pt/"  rel="noopener noreferrer" style={{ fontSize: "16px", textDecoration: "none", color: "#231F20" }}>
                SW
              </a>
            </Typography>

            <Typography
              variant="subtitle1"
              noWrap
              component="div"
              className="titleHeader"
              pr="30px"
            >
              <a href="https://workers.swork.pt/"  rel="noopener noreferrer" style={{ fontSize: "16px", textDecoration: "none", color: "#231F20" }}>
                Candidaturas
              </a>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              component="div"
              className="titleHeader"
              pr="30px"
            >
             <a href="https://new.swork.pt/pedido-de-proposta/" rel="noopener noreferrer" style={{ fontSize: "16px", textDecoration: "none", color: "#231F20" }}>
              Pedir proposta
              </a>
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              component="div"
              className="titleHeader"
              pr="30px"
            >
               <a href="https://new.swork.pt/contactos-recrutamento/" rel="noopener noreferrer" style={{ fontSize: "16px", textDecoration: "none", color: "#231F20" }}>
                  Contactos
                </a>
            </Typography>
            {!isAuthenticated() ? (
              
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                className="titleHeader"
                pr="30px"
              >
                <Link style={{ fontSize:"16px", textDecoration: "none", color: "#231F20"}}
                  to="/register"
                >
                  Registar
                </Link>
              </Typography>
            ): " " }

            {!isAuthenticated() ? (
              
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                className="titleHeader"
                pr="30px"
              >
                <Link
                  to="/login"
                >
                  <FontAwesomeIcon 
                  style={{ fontSize:"30px", textDecoration: "none", color: "#48AA37" }} icon="fa-regular fa-user" />
                </Link>
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                className="titleHeader"
                pr="30px"
                onClick={() => signOut()}
              >
                Logout
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              mt: 7,
            },
          }}
        >
          <HeaderMenu />
        </Drawer>
      </Box>
    </Box>
  );
}