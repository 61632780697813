import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ListOffer from "../components/ListOffer";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useIsAuthenticated } from "react-auth-kit";
import { useAuthUser } from 'react-auth-kit';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AdjustIcon from '@mui/icons-material/Adjust';
import BoltIcon from '@mui/icons-material/Bolt';
import Message from "../components/Message";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import BtnWhatsapp from "../components/BtnWhatsapp";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Offer() {
  //Get Offers
  const [offer, setOffer] = useState([]);
  const { id } = useParams();
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  //SnackBar Message
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("A sua candidatura foi efectuada com sucesso!");


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    const abortCont = new AbortController();

    axios
      .get(`${process.env.REACT_APP_API}/offer/${id}`, {
        signal: abortCont.signal,
      })
      .then((res) => {
        console.log(res.data);
        setOffer(res.data);

      })
      .catch((err) => {
        // console.log(err);
      });

    return () => abortCont.abort();
  }, [id]);
  //

  //Filtrar Related
  const inputValueJob = "";
  const inputValueGroup = offer.RECRUITMENT_GROUP;

  const handleCandidatura = (event, index) => {
    axios
      .post(`${process.env.REACT_APP_API}/offerapply`, {
        RECRUITMENT_REFERENCE: index
      }, {
        headers: {
          Authorization: "Bearer " + auth().token,
        },
      })
      .then((res) => {
        setMessage(res.data[0].RESULT);
        setAlert(true);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });

  }

  function getLocalOfferFormat(distrito, concelho, freguesia, local) {
    if (local !== null) return local;
    if (freguesia !== null) return freguesia;
    if (concelho !== null) return concelho;
    if (distrito !== null) return distrito;

    return "";
  }

  return (
    <div>
      <Header />
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        textAlign="left"
        sx={{
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "0px" },
          marginTop: { xs: "15vh", sm: "40vh", md: "40vh", lg: "35vh" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          className="nameHome"
          sx={{
            padding: { xs: "0px", sm: "0px", md: "0px", lg: "100px" },
            textAlign: { xs: "center", sm: "center", md: "center", lg: "left" },
          }}
        >
          <Typography
            variant="h3"
            pb={2}
            className="titleBold"
            sx={{
              fontSize: { xs: "2.5rem", sm: "2.5rem", md: "3rem", lg: "4rem" },
            }}
          >
            {offer.OFFER_AD_TITLE ? offer.OFFER_AD_TITLE : offer.OFFER_JOB}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          className="bckretangle"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            alignItems: "center",
          }}>
         
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        textAlign="left"
        sx={{
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "100px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "100px" },
          marginTop: { xs: "10vh", sm: "20vh", md: "20vh", lg: "24vh" },
        }}
      >
        <Grid item xs={12} sm={4}>
          <Grid container sx={{ display: "block" }}>
            <Grid item p={3} className="bgGreen rights borderR10">
              {isAuthenticated() === false &&
                <Link to={`/register`} style={{ textDecoration: 'none', color: 'white' }}>
                  <Typography variant="h5" >Candidate-se</Typography>
                </Link>
              }

              {              
              isAuthenticated() === true &&
              <Button onClick={handleClickOpen}> 
                  <Typography variant="h5" color={"white"}>Candidate-se</Typography>
                </Button>
              }
            
            </Grid>
            <Grid item xs={12} p={1}>
                <Message
                  message={message}
                  alert={alert}
                  setAlert={(updated) => setAlert(updated)}
                />
              </Grid>
            <Grid item mt={3} className="bgGrey borderR10">
              <Card className="cardOffer">
                <CardContent>
                  <Typography p={3} variant="body2" className="cardCity">
                    Referência <br />
                    {offer.RECRUITMENT_REFERENCE}
                  </Typography>
                  <Typography p={3} variant="body2" className="cardCity">
                    <FmdGoodIcon /> {getLocalOfferFormat(offer.OFFER_AD_DISTRITO, offer.OFFER_AD_CONCELHO, offer.OFFER_AD_FREGUESIA, offer.OFFER_AD_LOCAL)}
                    <br />
                    { offer.OFFER_JOB_GROUP &&<>
                      <BoltIcon className="iconoffer" /> {offer.OFFER_JOB_GROUP}
                    </>}
                    <br />
                    {offer.WORKING_HOURS && <>
                    <AdjustIcon className="iconoffer" />{offer.WORKING_HOURS}</>}

                  </Typography>
                  <Typography p={3} variant="body2" className="cardDate">
                    Publicado em {offer.CREATED_DATE}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item mt={3} p={3} className="bgGreen rights borderR10">
              <Typography variant="h5">Fale connosco</Typography>
            </Grid>
            <Grid item mt={3} className="bgGrey borderR10">
              <Card className="cardOffer">
                <CardContent>
                  <Grid
                    item
                    className="shareImage"
                    mt={3}
                    sx={{
                      paddingLeft: { xs: 0, sm: 3, md: 3 },
                      paddingRight: { xs: 0, sm: 3, md: 3 },
                    }}
                  >
                    <a
                      href="https://www.facebook.com/successworkpt/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon="fa-brands fa-facebook-f"
                        className="iconRightFooter2"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/successworkpt/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon="fa-brands fa-linkedin-in"
                        className="iconRightFooter2"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/successworkpt/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon="fa-brands fa-instagram"
                        className="iconRightFooter2"
                      />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send/?phone=926234709&text=Ao+iniciar+esta+conversa+autoriza+que+a+Success+Work+processe+e+trate+os+seus+dados+pessoais%2C+de+acordo+com+a+sua+politica+de+privacidade+dispon%C3%ADvel+em%3A+https%3A%2F%2Fswork.pt%2Fpolitica-da-privacidade%2F&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon="fa-brands fa-whatsapp"
                        className="iconRightFooter2"
                      />
                    </a>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid
            container
            pl="100px"
            sx={{
              display: "block",
              paddingLeft: { xs: "0px", sm: "0px", md: "100px", lg: "100px" },
            }}
          >
            {offer.OFFER_AD_TEXT &&
            <Grid
              item
              sx={{
                paddingTop: {
                  xs: "100px",
                  sm: "0px",
                  md: "100px",
                  lg: "100px",
                },
              }}
            >
              <Typography variant="h4" className="title">
                Queres ser um Success Worker?
              </Typography>
              <Typography pt={2}  variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT}
              </Typography>
            </Grid>
          }

          {offer.OFFER_AD_TEXT_TASKS && 
            <Grid item mt={10}>
              <Typography variant="h4" className="title">
                O profissional a selecionar terá como principais
                responsabilidades:
              </Typography>
              <Typography pt={2}  variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT_TASKS}
              </Typography>
            </Grid>
            }

            {offer.OFFER_AD_TEXT_PROFILE && 
            <Grid item mt={8}>
              <Typography variant="h4" className="title">
                Perfil pretendido:
              </Typography>
              <Typography pt={2}  variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT_PROFILE}
              </Typography>
            </Grid>
          }

          { offer.OFFER_AD_TEXT_BENEFITS && 
            <Grid item mt={8}>
              <Typography variant="h4" className="title">
                Oferece-se:
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT_BENEFITS}
                <div dangerouslySetInnerHTML={{ __html: offer.text }} />
              </Typography>
            </Grid>
            }

            { offer.OFFER_AD_TEXT_INFO && 
            <Grid item mt={8}>
              <Typography variant="h4" className="title">
                Observações
              </Typography>
              <Typography pt={2} variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT_INFO}
              </Typography>
            </Grid>
          }
            <Grid item>
              <Typography pt={2} variant="subtitle1"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {offer.OFFER_AD_TEXT_INFO_2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="false" className="contactContainerOffer">
        <Grid
          container
          p={6}
          direction="row"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          sx={{
            paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
            paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "80px" },
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                paddingBottom: { xs: "40px", sm: "40px", md: "0", lg: "0" },
              }}
            >
              Tens dúvidas?
              <br />
              Estamos aqui para ajudar.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h5" pb={2}>Fale connosco</Typography>
            <a href="callto:+351289388531" className="talk">
              Contacte-nos hoje
            </a><br />
            <Typography variant="p" className="talkinfo">(Chamada para rede móvel nacional)</Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        textAlign="center"
        sx={{
          paddingTop: { xs: "30px", sm: "30px", md: "30px", lg: "30px" },
          paddingLeft: { xs: "50px", sm: "50px", md: "50px", lg: "100px" },
          paddingRight: { xs: "50px", sm: "50px", md: "50px", lg: "95px" },
          display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h6"
            className="relatedText"
            sx={{
              paddingBottom: { xs: "30px", sm: "30px", md: "0px", lg: "0px" },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "left",
                lg: "left",
              },
            }}
          >
            Ofertas relacionadas
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h6"
            className="relatedText"
            sx={{
              paddingBottom: { xs: "30px", sm: "30px", md: "0px", lg: "0px" },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "right",
                lg: "right",
              },
            }}
          >
            <a href="https://workers.swork.pt/"  className="linkHome">
              Ver todas as ofertas
            </a>
          </Typography>
        </Grid>
      </Grid>
      {/* Colocar filtro por uma propriedade do objecto */}
      <ListOffer
        inputValueJob={inputValueJob}
        inputValueGroup={inputValueGroup}
        numberOfItems={3}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirma a sua candidatura?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={(event) => { handleCandidatura(event, id) }}>Sim</Button>
          <Button onClick={handleClose}>Não</Button>
        </DialogActions>
      </Dialog>

      <Footer />
  <BtnWhatsapp />
    </div>
  );
}
